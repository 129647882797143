import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingService } from '@capturum/ui/loader';
import { TranslateService } from '@ngx-translate/core';
import { CompleteConfig, PublicTranslationResolver, PublicTranslationService } from '@capturum/complete';
import { first } from 'rxjs/operators';

@Injectable()
export class CustomPublicTranslationResolver extends PublicTranslationResolver implements Resolve<Observable<any>> {
  constructor(
    publicTranslationService: PublicTranslationService,
    private loadingService: LoadingService,
    private coreConfig: CompleteConfig,
    private translateService: TranslateService,
  ) {
    super(publicTranslationService);
  }

  /**
   * @inheritDoc
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.loadingService.toggleLoading(true,
        this.coreConfig.useTranslatedLoaderText ? this.translateService.instant(this.coreConfig.loaderText) : this.coreConfig.loaderText,
        this.coreConfig.loaderImage,
        'complete-resolver-loader'
      );

      super.resolve(route, state)
        .pipe(first())
        .subscribe(() => {
          this.loadingService.hideLoader();

          observer.next(true);
          observer.complete();
        }, (err) => {
          this.loadingService.hideLoader();

          observer.error(err);
          observer.complete();
        });
    });
  }
}
