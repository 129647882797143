import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { FilterConfig } from '@core/models/filter-config.model';
import { PickListColumn } from '@core/models/pick-list-column';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickListComponent {
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  @Input()
  public source: any[];

  @Input()
  public target: any[];

  @Input()
  public columns: PickListColumn[];

  @Input()
  public filters?: FilterConfig[];

  @Input()
  public sourceCardHeader: string;

  @Input()
  public sourceCardIcon: string;

  @Input()
  public targetCardHeader: string;

  @Input()
  public targetCardIcon: string;

  @Output()
  public onFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public remove: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public add: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public addAll: EventEmitter<void> = new EventEmitter<void>();
}
