<div class="sub-menu-item" [ngClass]="styleClass">
  <i class="sub-menu-item--icon" [ngClass]="icon"></i>

  <div class="sub-menu-item--title">
    {{ title }}
  </div>

  <div class="sub-menu-item--subtitle">
    <ng-container [ngTemplateOutlet]="subtitleTemplate || default">
    </ng-container>
  </div>

  <ng-template #default>
    {{ subTitle }}
  </ng-template>
</div>
