<div class="accordion-item">
  <p-accordion expandIcon="pi pi-fw pi-chevron-up">
    <p-accordionTab [header]="roleType?.key + ' (' + roleType?.value?.length + ' ' + (roleType?.value?.length > 1 ? ('intergrip.role.plural' | translate) : ('intergrip.role.entity_name' | translate)) + ')'">
      <ng-template pTemplate="content">
        <div class="overflow-auto">
          <div class="table-header row mt-0 mx-0">
            <div class="col">{{ 'intergrip.role.module_id.label' | translate }}</div>
            <div class="col">{{ 'intergrip.role.instance_id.label' | translate }}</div>
            <div class="col-2"></div>
          </div>

          <div *ngFor="let role of roleType.value; let last = last let first = first; let index = index" class="table-item row mx-0">
            <div class="col">{{ role?.module?.name }}</div>
            <div class="col">{{ role?.tenant }}</div>
            <div class="action-column col-2"><i *ngIf="isDeletable" class="fas fa-trash-alt" (click)="deleteRole.emit({ index: index, role: role })"></i></div>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
