import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'baseDataTranslation'
})
export class BaseDataTranslationPipe implements PipeTransform {
  constructor(private translatePipe: TranslatePipe) {
  }

  public transform(value: string): string {
    return value ? this.translatePipe.transform(`base-data.${value}`) : '-';
  }
}
