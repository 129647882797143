import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ModelLog } from '@capturum/complete';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Meta } from '@capturum/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-model-log-timeline',
  templateUrl: './model-log-timeline.component.html',
  styleUrls: ['./model-log-timeline.component.scss'],
})
export class ModelLogTimelineComponent implements OnInit {
  public modelLogs: Record<string, ModelLog[]> = {};
  public historyLogPage = 1;
  public itemsPerPage = 10;
  public totalModelLogs: number;
  public currentUserId: string;
  public loadModelLogs: (id: string, page: number, perPage: number) => Observable<{ data: Record<string, ModelLog[]>, meta: Meta }>;

  constructor(
    @Optional()
    public config: DynamicDialogConfig,
    @Optional()
    private dialogRef: DynamicDialogRef,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    if (this.config?.data) {
      this.loadModelLogs = this.config.data.loadModelLogs;
      this.loadData(this.config.data.id);
    }
  }

  public loadData(id: string, page: number = 1, perPage: number = this.itemsPerPage): void {
    this.loadModelLogs(id, page, perPage).subscribe((response) => {
      this.modelLogs = { ...this.modelLogs, ...response.data };
      this.historyLogPage = page;
      this.currentUserId = id;
      this.totalModelLogs = response.meta.pagination.total;
      this.cdr.detectChanges();
    });
  }
}
