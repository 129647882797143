import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ApiHttpService, ApiService } from '@capturum/api';
import { responseData, toMapItems } from '@core/utils/converter.utils';
import { map } from 'rxjs/operators';
import { UserModel } from '../../user/models/user-model.interface';
import { TranslateService } from '@ngx-translate/core';
import { TwoFactorMethod } from '@core/enums/helpers/two-way-auth.enum';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthService extends ApiService<any> {
  protected endpoint = 'auth';

  constructor(
    private toastService: ToastService,
    private api: ApiHttpService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
    super(api);
  }

  public list2faMethods(): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/2fa/methods`).pipe(
      toMapItems,
    );
  }

  public login(postData: { user_id: string, method: string, key: string }): Observable<{ token: string, user: UserModel }> {
    return this.apiHttp.post(`/${this.endpoint}/login2fa`, postData);
  }

  public getGoogleQrCode(): Observable<{ qrCode: SafeResourceUrl, secret: string }> {
    return this.apiHttp.get<{ data: { qrCode: string, secret: string } }>(`/${this.endpoint}/2fa/setup/google`)
      .pipe(
        map((response) => ({
          ...response.data,
          qrCode: this.sanitizer.bypassSecurityTrustResourceUrl(response.data.qrCode),
        })),
      );
  }

  public confirm2fa(method: string, key: string): Observable<{ success: boolean }> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/confirm`, { method, key }).pipe(responseData);
  }

  public setupEmail(): Observable<{ identifier: string }> {
    return this.apiHttp.get(`/${this.endpoint}/2fa/setup/email`).pipe(responseData);
  }

  public register(method: string, identifier: string): Observable<{ success: boolean }> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/register`, { method, identifier }).pipe(responseData);
  }

  public setupSms(): Observable<{ identifier: string }> {
    return this.apiHttp.get(`/${this.endpoint}/2fa/setup/sms`).pipe(responseData);
  }

  public send2faResetMail(user: { id: string, name: string }): void {
    this.apiHttp.post(`/${this.endpoint}/2fa/send-disable-email`, { user_id: user.id }).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('intergrip.2fa.toast.sent-email', { name: user.name }),
      );
    });
  }

  public disable2fa(token: string): Observable<{ success: boolean } | string[]> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/disable?token=${token}`, { token });
  }

  public requestDisable(): Observable<{ success: boolean }> {
    return this.apiHttp.get(`/${this.endpoint}/2fa/request-disable`);
  }

  public confirmDisable(method: TwoFactorMethod, key: string): Observable<{ success: boolean }> {
    return this.apiHttp.post(`/${this.endpoint}/2fa/confirm-disable`, { method, key });
  }
}
