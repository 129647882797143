<app-table-filter *ngIf="filterConfig"
                  [filters]="filterConfig"
                  (onReset)="reset()"
                  (onFilter)="filter($event)"
                  [activeFilters]="activeFilters">
</app-table-filter>

<ng-container *ngIf="tableData && tableData.length > 0; else noContent">
  <table class="pick-list-table">
    <thead class="pick-list-header-row">
    <th *ngFor="let column of columns" class="pick-list-header-column">
      {{ column?.title | observablePipe | async }}
    </th>

    <th *ngIf="isSourceTable" (click)="addAll.emit()" class="text-right text-primary select-column">
      <span class="select-column">
        {{ 'intergrip.pick-list.select-all.label' | translate }} <i class="ml-2 icon fas fa-arrow-right"></i>
      </span>
    </th>

    <th *ngIf="!isSourceTable" class="text-right">
      &nbsp;
    </th>
    </thead>

    <tbody>
    <tr *ngFor="let item of tableData; let rowIndex = index;" class="pick-list-body-row">
      <td *ngFor="let column of columns" class="pick-list-body-column">
        <ng-container [ngSwitch]="column?.type">
          <ng-container *ngSwitchCase="DataTableType.Template">
            <ng-container *ngIf="templates | columnTemplate: column?.field as customTemplate"
                          [ngTemplateOutletContext]="{item: item, index: rowIndex}"
                          [ngTemplateOutlet]="customTemplate">
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <span [pTooltip]="item | propertyValue: column?.field" appendTo="body">
              {{ item | propertyValue: column?.field }}
            </span>
          </ng-container>
        </ng-container>
      </td>

      <td class="text-right">
        <i *ngIf="isSourceTable" class="icon select-column fas fa-arrow-right text-primary"
           (click)="add.emit(item)"></i>
        <i *ngIf="!isSourceTable" class="icon fas fa-trash text-danger" (click)="remove.emit(item)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #noContent>
  <div class="w-100 mt-3">
    <span *ngIf="isSourceTable" class="no-content">{{ 'intergrip.pick-list.no-content.label' | translate }}</span>
    <span *ngIf="!isSourceTable" class="no-content">{{ 'intergrip.pick-list.no-items-picked.label' | translate }}</span>
  </div>
</ng-template>
