<nav class="navbar navbar-static-top white-bg header d-flex" role="navigation">
  <div class="bar max-w">
    <div class="logo">
      <a routerLink="/">
        <img [src]="'./assets/images/logo.png'" alt="logo" />
      </a>
    </div>

    <div class="header-menu">
      <div class="user" *ngIf="user$ | async as user">
        <span class="name" (click)="navigateToProfile()">{{ user?.name }}</span>
        <span class="role" (click)="overlayPanel.toggle($event)">
          <span>
            {{ user.currentRoleType.name || '-' }}: {{ user?.currentInstance?.name }}
          </span>
          <i [class]="overlayPanel.overlayVisible ? 'far fa-chevron-up' : 'far fa-chevron-down'"></i>
          </span>
      </div>

      <div class="to-profile-menu" (click)="localePanel.toggle($event);">
        <i class="fa-ellipsis-v fas"></i>
      </div>
    </div>
  </div>

  <div class="modules bar row">
    <div *ngIf="showSliderArrows" class="app-header__arrow" (click)="swipeModule(arrowSide.Left)">
      <i  class="fas fa-chevron-left"></i>
    </div>

    <cap-horizontal-navbar [class]="{'app-header__modules-list': showSliderArrows}" #modulesMenu [items]="moduleMenuItems$ | async">
      <ng-template capTemplate="icon" let-item>
        <ng-container *ngIf="item.key !== 'intergrip'; else defaultTemplate">
          <img class="module-logo" [src]="item.key | moduleImage" alt="module logo">
        </ng-container>

        <ng-template #defaultTemplate>
          <i [class]="item.icon"></i>
        </ng-template>
      </ng-template>

      <ng-template capTemplate="nav-item" let-item>
        <span class="module-name">{{ item.title | translate }}</span>
      </ng-template>
    </cap-horizontal-navbar>
    <div *ngIf="showSliderArrows" class="app-header__arrow app-header__arrow--right" (click)="swipeModule(arrowSide.Right)">
      <i class="fas fa-chevron-right"></i>
    </div>

  </div>
</nav>

<p-overlayPanel #overlayPanel [style]="{ width: '330px' }">
  <app-role-selector [schoolYears]="schoolYears"
                     (onSelectRole)="setActiveRole($event); overlayPanel.hide()"
                     (onFavoriteRole)="updateFavoriteRole($event)">
  </app-role-selector>
</p-overlayPanel>

<p-overlayPanel #localePanel [style]="{ width: '330px' }" appendTo="body">
  <div class="profile-menu">
    <ng-container *ngFor="let item of profileMenuItems$ | async">
      <div *ngIf="!item.disabled"
           class="profile-menu-item"
           (click)="onClickLocale(item);
           localePanel.hide()"
           [class.selected-lang]="selectedLanguage === item.title">
        <i *ngIf="!item.title" class="{{ item.icon }}"></i>
        <img *ngIf="item.title" class="img-locale" src="assets/flags/{{ item.title }}.svg">
        <span>{{ item.label$ | async }}</span>
      </div>
      <div *ngIf="item.disabled"
           class="profile-menu-item item-disabled"
           (click)="localePanel.hide()">
        <span>{{ item.label$ | async }}</span>
      </div>
    </ng-container>
  </div>
</p-overlayPanel>
