import { Injectable } from '@angular/core';
import { UriUtils } from '@shared/utils/uri.utils';
import { Entities } from '@core/components/entities.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { TranslateService } from '@ngx-translate/core';
import { Modules } from '@core/enums/modules.enum';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  public menuItems: NavMenuItem[] = [
    {
      title: 'intergrip.menu.transfer',
      activeSubRoute: '/transfer-vo',
      routerUrl: UriUtils.list(Entities.phases),
      exact: false,
      key: 'transfer-vo',
      icon: 'fas fa-sync',
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.phases',
          routerUrl: UriUtils.list(Entities.phases, Modules.transferVo),
          icon: 'fas fa-sync',
          permissions: ['transfer-vo.signup-phase.show'],
          dataTest: 'menu-item-phases',
        },
        {
          title: 'intergrip.menu.signup-status',
          routerUrl: UriUtils.list(Entities.signupStatus, Modules.transferVo),
          icon: 'fas fa-sliders-h',
          permissions: ['transfer-vo.signup-status.show', 'transfer-vo.signup-status.manage'],
          dataTest: 'menu-item-status',
        },
        {
          title: 'intergrip.menu.todo-student',
          routerUrl: UriUtils.submenu(Entities.todoStudents, Modules.transferVo),
          icon: 'fas fa-users',
          permissions: [
            'transfer-vo.todo-student.show',
            'transfer-mbo.todo-student.show',
            'transfer-vo.student.show',
            'transfer-mbo.student.show',
          ],
          dataTest: 'menu-item-todo-student',
        },
        {
          title: 'intergrip.menu.transfer-reports',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.transferVo),
          icon: 'fas fa-chart-line',
          permissions: [
            'transfer-vo.choice-student.export',
            'transfer-vo.choice-student.best-choice-export',
            'transfer-vo.mbo-choice-student.export',
          ],
          dataTest: 'menu-item-transfer-reports',
        },
      ],
    },
    {
      title: 'intergrip.menu.transfer-mbo',
      activeSubRoute: '/transfer-mbo',
      routerUrl: UriUtils.list(Entities.phases),
      exact: false,
      key: 'transfer-mbo',
      icon: 'fas fa-sync',
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.todo-student',
          routerUrl: UriUtils.submenu(Entities.todoStudents, Modules.transferMbo),
          icon: 'fas fa-users',
          permissions: [
            'transfer-mbo.mbo.todo-student.show',
            'transfer-mbo.mbo.student.show',
          ],
          dataTest: 'menu-item-todo-student',
        },
        {
          title: 'intergrip.menu.transfer-reports',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.transferMbo),
          icon: 'fas fa-chart-line',
          permissions: [
            'transfer-mbo.mbo-mbo.choice-student.export',
            'transfer-mbo.mbo-mbo.best-choice-student.export'
          ],
          dataTest: 'menu-item-transfer-reports',
        },
      ],
    },
    {
      title:  'intergrip.menu.lobdossier',
      activeSubRoute: '/lobdossier',
      routerUrl: UriUtils.list(Entities.lobDossier),
      exact: false,
      key: Entities.lobDossier,
      icon: 'fas fa-dossier',
      permissions: [],
      children: [
        {
          title: 'lobdossier.menu.management.title',
          routerUrl: UriUtils.submenu(Entities.management, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.management.show'],
        },
        {
          title: 'lobdossier.menu.assignment.title',
          routerUrl: UriUtils.submenu(Entities.assignment, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.assignment.show'],
        },
        {
          title: 'lobdossier.menu.lob-assignment.title',
          routerUrl: UriUtils.submenu(Entities.lobAssignment, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.lob-assignment.show'],
        },
        {
          title: 'lobdossier.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.lobdossierMyStudents, Modules.lobdossier),
          icon: 'fas fa-users',
          permissions: ['lobdossier.my-students.show'],
        },
        {
          title: 'lobdossier.menu.overview-students.title',
          routerUrl: UriUtils.submenu(Entities.lobdossierOverviewStudents, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.overview-students.show'],
        },
        {
          title: 'lobdossier.menu.carrier-assignment.title',
          routerUrl: UriUtils.submenu(Entities.carrierAssignment, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.carrier-assignment.show', 'lobdossier.mbo-carrier-assignment.show'],
        },
        {
          title: 'lobdossier.menu.handed-in-assignments.title',
          routerUrl: UriUtils.submenu(Entities.handedInAssignments, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.vo-handed-in-assignments.show', 'lobdossier.mbo-handed-in-assignments.show'],
        },
        {
          title: 'lobdossier.menu.handed-in-assignments.not-assigned.title',
          routerUrl: UriUtils.submenu(Entities.notAssigned, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.handed-in-assignments.not-assigned.show'],
        },
        {
          title: 'lobdossier.menu.report.title',
          routerUrl: UriUtils.submenu(Entities.lobdossierReports, Modules.lobdossier),
          icon: 'fas fa-folder-open',
          permissions: ['lobdossier.report.show'],
        },
      ],
    },
    {
      title: 'intergrip.menu.lobactivities',
      activeSubRoute: '/lobactivities',
      routerUrl: UriUtils.list(Entities.lobActivities),
      exact: false,
      key: Entities.lobActivities,
      icon: 'fas fa-dossier',
      permissions: [],
      children: [
        {
          title: 'lobactivities.menu.activities.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-i-cursor',
          permissions: ['lobactivities.activities.show'],
        },
        {
          title: 'lobactivities.menu.import.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-download',
          permissions: ['lobactivities.import.show'],
        },
        {
          title: 'lobactivities.menu.offer.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-server',
          permissions: ['lobactivities.offer.show'],
        },
        {
          title: 'lobactivities.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-user-times',
          permissions: ['lobactivities.my-students.show'],
        },
        {
          title: 'lobactivities.menu.registrations.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-sign-in-alt',
          permissions: ['lobactivities.registrations.show', 'lobactivities.mbo-registrations.show', 'lobactivities.vo-registrations.show'],
        },
        {
          title: 'lobactivities.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-sliders-h',
          permissions: ['lobactivities.settings.show', 'lobactivities.admin-settings.show', 'lobactivities.vo-settings.show'],
        },
        {
          title: 'lobactivities.menu.students.title',
          routerUrl: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
          icon: 'fas fa-users',
          permissions: ['lobactivities.students.show'],
        },
      ],
    },
    {
      title: 'intergrip.menu.ddd',
      activeSubRoute: '/ddd',
      routerUrl: UriUtils.list(Entities.training),
      exact: false,
      key: 'ddd',
      icon: 'fas fa-dossier',
      permissions: [],
      children: [
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: UriUtils.submenu(Entities.dossiers, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.dossier.show'],
        },
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: UriUtils.submenu(Entities.mboDossiers, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.mbo-dossier.show'],
        },
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: UriUtils.submenu(Entities.voDossiers, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.vo-dossier.show'],
        },
        {
          title: 'ddd.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.ddd),
          icon: 'fas fa-chart-line',
          permissions: ['ddd.report.show'],
        },
        {
          title: 'ddd.menu.special-transfers.title',
          routerUrl: UriUtils.submenu(Entities.specialTransfers, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.mbo-special-transfer.show', 'ddd.vo-special-transfer.show'],
        },
        {
          title: 'ddd.menu.trainings.title',
          routerUrl: UriUtils.submenu(Entities.training, Modules.ddd),
          icon: 'fas fa-map-signs',
          permissions: ['ddd.training.show'],
        },
        {
          title: 'ddd.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.setting, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.mbo-settings.show'],
        },
        {
          title: 'ddd.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.subMenuSettings, Modules.ddd),
          icon: 'fas fa-cog',
          permissions: ['ddd.settings.show'],
        },
        {
          title: 'ddd.menu.pipeline.title',
          routerUrl: UriUtils.submenu(Entities.pipeline, Modules.ddd),
          icon: 'fas fa-code-branch',
          permissions: ['ddd.pipeline.show'],
        },
        {
          title: 'ddd.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.voReports, Modules.ddd),
          icon: 'fas fa-chart-line',
          permissions: ['ddd.vo-report.show'],
        },
        {
          title: 'ddd.menu.setup.title',
          routerUrl: UriUtils.submenu(Entities.setup, Modules.ddd),
          icon: 'fas fa-university',
          permissions: ['ddd.setup.show'],
        },
        {
          title: 'ddd.menu.opp.title',
          routerUrl: UriUtils.submenu(Entities.opps, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.vo-opp.show'],
        },
        {
          title: 'ddd.menu.student.title',
          routerUrl: UriUtils.submenu(Entities.myStudents, Modules.ddd),
          icon: 'fas fa-child',
          permissions: ['ddd.student.show'],
        },
        {
          title: 'ddd.menu.mbo-institutions.title',
          routerUrl: UriUtils.submenu(Entities.institutions, Modules.ddd),
          icon: 'fas fa-bars',
          permissions: ['ddd.mbo-institution.show'],
        },
        {
          title: 'ddd.menu.opp.title',
          routerUrl: UriUtils.submenu(Entities.opp, Modules.ddd),
          icon: 'fas fa-fax',
          permissions: ['ddd.opp.show'],
        },
        {
          title: 'ddd.menu.special-transfers.title',
          routerUrl: UriUtils.submenu(Entities.specialTransferManage, Modules.ddd),
          icon: 'fas fa-fire',
          permissions: ['ddd.special-transfer.show'],
        },
        {
          title: 'ddd.menu.form-setting.title',
          routerUrl: UriUtils.submenu(Entities.formSettings, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.form-setting.show'],
        },
        {
          title: 'ddd.menu.transfers.title',
          routerUrl: UriUtils.submenu(Entities.transfers, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.transfer.show'],
        },
        {
          title: 'ddd.menu.connection.title',
          routerUrl: UriUtils.submenu(Entities.connections, Modules.ddd),
          icon: 'fas fa-folder-open',
          permissions: ['ddd.connection.show'],
        },
        {
          title: 'ddd.menu.tool.title',
          routerUrl: UriUtils.submenu(Entities.tools, Modules.ddd),
          icon: 'fas fa-clock',
          permissions: ['ddd.tool.show'],
        },
      ],
    },
    {
      title: 'intergrip.menu.manage',
      activeSubRoute: '/manage',
      routerUrl: UriUtils.list(Entities.students),
      exact: false,
      icon: 'fas fa-cog',
      key: 'manage',
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.students',
          routerUrl: UriUtils.submenu(Entities.students),
          icon: 'fas fa-users',
          permissions: ['intergrip.student.show'],
          dataTest: 'menu-item-students',
        },
        {
          title: 'intergrip.menu.users',
          routerUrl: UriUtils.submenu(Entities.users),
          icon: 'fas fa-user',
          permissions: ['intergrip.user.show', 'user.manage', 'user.show', 'intergrip.user.manage'],
          dataTest: 'menu-item-users',
        },
        {
          title: 'intergrip.menu.instances',
          routerUrl: UriUtils.submenu(Entities.instances),
          icon: 'fas fa-building',
          permissions: [
            'intergrip.municipality.show',
            'intergrip.school.show',
            'intergrip.region.show',
            'intergrip.mbo-institution.show',
            'intergrip.vo-institution.show',
            'intergrip.vo-school-group.show',
          ],
          dataTest: 'menu-item-instances',
        },
        {
          title: 'intergrip.menu.communication',
          routerUrl: UriUtils.submenu(Entities.communications),
          icon: 'fas fa-envelope',
          permissions: ['intergrip.communication.show'],
          dataTest: 'menu-item-communication',
        },
        {
          title: 'transfer-vo.menu.mbo-checks',
          routerUrl: UriUtils.submenu(Entities.mboCheck),
          icon: 'fas fa-tasks',
          permissions: ['transfer-vo.mbo-check.show', 'transfer-mbo.mbo-check.show'],
          dataTest: 'menu-item-mbo-checks',
        },
        {
          title: 'intergrip.menu.other-management',
          routerUrl: '/admin/manage/other-settings',
          icon: 'fas fa-ellipsis-v',
          permissions: ['intergrip.shared-file.show'],
          dataTest: 'menu-item-other-management',
        },
      ],
    },
    {
      title: 'intergrip.menu.monitorus',
      activeSubRoute: '/monitorus',
      routerUrl: UriUtils.list(Entities.monitorusList),
      exact: false,
      key: 'monitorus',
      icon: 'fas fa-dossier',
      permissions: [],
      children: [
        {
          title: 'monitorus.menu.import.title',
          routerUrl: UriUtils.submenu(Entities.monitorusImport, Modules.monitorus),
          icon: 'fas fa-download',
          permissions: ['monitorus.import.show'],
        },
        {
          title: 'monitorus.menu.data.title',
          routerUrl: UriUtils.submenu(Entities.monitorusData, Modules.monitorus),
          icon: 'fas fa-university',
          permissions: ['monitorus.data.show'],
        },
        {
          title: 'monitorus.menu.info.title',
          routerUrl: UriUtils.submenu(Entities.monitorusInfo, Modules.monitorus),
          icon: 'fas fa-info-circle',
          permissions: ['monitorus.info.show'],
        },
        {
          title: 'monitorus.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.monitorusReportsSubmenu, Modules.monitorus),
          icon: 'fas fa-info-circle',
          permissions: ['monitorus.reports.show', 'monitorus.mbo-reports.show'],
        },
        {
          title: 'monitorus.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.monitorusSettings, Modules.monitorus),
          icon: 'fas fa-cogs',
          permissions: ['monitorus.settings.show'],
        },
        {
          title: 'monitorus.menu.list.title',
          routerUrl: UriUtils.submenu(Entities.monitorusList, Modules.monitorus),
          icon: 'fas fa-bars',
          permissions: ['monitorus.list.show'],
        },
        {
          title: 'monitorus.menu.import-files.title',
          routerUrl: UriUtils.submenu(Entities.monitorusImportFiles, Modules.monitorus),
          icon: 'fas fa-info-circle',
          permissions: ['monitorus.import-files.show'],
        },
      ],
    },
    {
      title: 'intergrip.menu.zoekjouwstudie',
      activeSubRoute: '/zoekjouwstudie',
      routerUrl: UriUtils.list(Entities.lobVoorlichtingenManagement),
      exact: false,
      key: 'zoekjouwstudie',
      icon: 'fas fa-dossier',
      permissions: [],
      children: [
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-management.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.lobVoorlichtingen),
          icon: 'fas fa-folder-open',
          permissions: ['zoekjouwstudie.management.show', 'zoekjouwstudie.management-admin.show'],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-settings.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenSettings, Modules.lobVoorlichtingen),
          icon: 'fas fa-cog',
          permissions: ['zoekjouwstudie.settings.show'],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-offer.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.lobVoorlichtingen),
          icon: 'fas fa-server',
          permissions: ['zoekjouwstudie.offer.show'],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-phases.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenPhases, Modules.lobVoorlichtingen),
          icon: 'fas fa-sliders-h',
          permissions: ['zoekjouwstudie.phases.show'],
        },
        {
          title: 'zoekjouwstudie.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenMyStudents, Modules.lobVoorlichtingen),
          icon: 'fas fa-users',
          permissions: ['zoekjouwstudie.my-students.show'],
        },
        {
          title: 'zoekjouwstudie.menu.activities.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenActivities, Modules.lobVoorlichtingen),
          icon: 'fas fa-microphone',
          permissions: ['zoekjouwstudie.activities.show'],
        },
        {
          title: 'zoekjouwstudie.menu.search.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenSearch, Modules.lobVoorlichtingen),
          icon: 'fas fa-search',
          permissions: ['zoekjouwstudie.search.show'],
        },
        {
          title: 'zoekjouwstudie.menu.registrations.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenRegistrations, Modules.lobVoorlichtingen),
          icon: 'fas fa-calendar',
          permissions: ['zoekjouwstudie.registrations.show'],
        },
        {
          title: 'zoekjouwstudie.menu.sort.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenSort, Modules.lobVoorlichtingen),
          icon: 'fas fa-exchange-alt',
          permissions: ['zoekjouwstudie.sort.show'],
        },
      ],
    },
  ];
  public activeMenuItem$: Observable<NavMenuItem[]>;
  public activeMenuItem = new BehaviorSubject<NavMenuItem>(null);

  constructor(private translateService: TranslateService) {
  }

  public setActiveMenuitem(navMenuItem: NavMenuItem): void {
    this.activeMenuItem.next(navMenuItem);
  }

  public getActiveMenuItem(): Observable<NavMenuItem> {
    return this.activeMenuItem.asObservable();
  }
}
