<emc-model-log-timeline [items]="modelLogs"
                        [translationKeyPrefix]="'intergrip'"
                        [emptyResultsTranslationKey]="'intergrip.model-log.no-history'">
  <ng-template capTemplate="header" let-item>
    {{ 'intergrip.model-log.' + item.action + '-by.label' | translate : {
    entity: item.entity_key ? (('intergrip.' + (item.entity_key | lowercase) + '.entity_name' | translate) | capitalizeFirst) : ('intergrip.model-log.entity' | translate),
    user: item.user.name ? item.user.name : ('intergrip.model-log.system-user' | translate)
    }
    }}

    <div class="timeline__created">
      {{ item.created_on | date: 'dd - MM - yyyy @ hh:mm' }}
    </div>
  </ng-template>
</emc-model-log-timeline>

<div class="user-history__load-more" (click)="loadData(currentUserId, historyLogPage + 1)"
     *ngIf="totalModelLogs && totalModelLogs > itemsPerPage * historyLogPage">
  {{ 'intergrip.model-log.load-more' | translate }}
</div>
