<div class="row">
  <div class="col-sm-12 col-md-6">
    <cap-card>
      <ng-template capTemplate="header">
        <span class="header">
          <i [class]="sourceCardIcon"></i><span class="card__title">{{ sourceCardHeader }}</span>
        </span>
      </ng-template>

      <ng-template capTemplate="content">
        <ng-container *ngIf="source && columns; else loading;">
          <app-pick-list-table [tableData]="source"
                               [templates]="templates"
                               [filterConfig]="filters"
                               [columns]="columns"
                               [isSourceTable]="true"
                               (add)="add.emit($event)"
                               (addAll)="addAll.emit($event)"
                               (onFilter)="onFilter.emit($event)">
          </app-pick-list-table>
        </ng-container>
      </ng-template>
    </cap-card>
  </div>

  <div class="col-sm-12 col-md-6">
    <cap-card>
      <ng-template capTemplate="header">
        <span class="header">
          <i [class]="targetCardIcon"></i><span class="card__title">{{ targetCardHeader }}</span>
        </span>
      </ng-template>

      <ng-template capTemplate="content">
        <ng-container *ngIf="target && columns; else loading;">
          <app-pick-list-table [tableData]="target"
                               [templates]="templates"
                               [columns]="columns"
                               (remove)="remove.emit($event)">
          </app-pick-list-table>
        </ng-container>
      </ng-template>
    </cap-card>
  </div>

  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</div>
