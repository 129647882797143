<div class="row">
  <ng-container  *ngFor="let menuItem of menuItems">
    <div class="col-xl-3 col-lg-4 mb-3" *ngxPermissionsOnly="menuItem.permissions">
      <app-in-screen-sub-menu-item [icon]="menuItem.locked ? 'fas fa-lock' : menuItem.icon"
                                   [title]="menuItem.title | translate"
                                   [styleClass]="menuItem.locked ? 'locked' : null"
                                   [subtitleTemplate]="menuItem.locked ? lockedTemplate : null"
                                   (click)="onItemClick(menuItem)"
                                   [subTitle]="menuItem.subTitle | translate"
                                   [attr.data-test]="menuItem?.data_test">
        <ng-template #lockedTemplate>
          <cap-button label="probeer deze module" icon="fas fa-chevron-circle-right" iconPos="right"
                      styleClass="secondary w-100"></cap-button>
        </ng-template>
      </app-in-screen-sub-menu-item>
    </div>
  </ng-container>
</div>
