<div class="col-12">
  <form class="login-form">
    <div class="title mb-0">
      <span class="text">Intergrip</span>
    </div>

    <div class="subtitle">
      <span class="text">{{ 'auth.2fa.' + method + '.login.subtitle' | translate }}</span>
    </div>

    <div class="mb-4 mx-auto mx-md-0">
      <app-input-code [formControl]="inputCodeControl"></app-input-code>
    </div>

    <div class="login-actions">
      <cap-button [label]="'auth.sign-in.button' | translate"
                  [disabled]="inputCodeControl?.invalid"
                  [class.disabled]="inputCodeControl?.invalid"
                  type="button"
                  styleClass="primary"
                  [attr.data-test]="'login-button'"
                  (click)="onSubmit()">
      </cap-button>

      <span class="login-separator">{{ 'auth.sign-in.or-label' | translate }}</span>

      <a routerLink="/auth/login"
         class="login-link"
         data-test="forgot-password-link">
        {{ 'auth.2fa.button.back-main-page' | translate }}
      </a>
    </div>
  </form>
</div>
