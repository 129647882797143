import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { SelectItem, SelectItemGroup } from 'primeng/api';

@Pipe({
  name: 'valuesToLabels',
})
export class ValuesToLabelsPipe implements PipeTransform {

  public transform(values: string[], options: SelectItemGroup[]): string[] {
    return values.map((value) => {
      let result: SelectItem;

      options.forEach((option) => {
        if (option.value === value) {
         result = option as SelectItem;
        }

        if (!result && option.items) {
          result = option.items.find((optionItem) => optionItem.value === value);
        }
      });

      return result?.label ?? null;
    });
  }
}
