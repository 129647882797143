import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToText',
})
export class ArrayToTextPipe implements PipeTransform {
  public transform(list: any[], valueProperty: string = 'name'): string {
    if (list) {
      return list.map((item) => item[valueProperty]).join(', ');
    }

    return '';
  }
}
