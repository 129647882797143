import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  NgZone,
  Optional,
  Output,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { PageConfigAction } from '@core/models/page-config.model';
import { Sidebar } from 'primeng/sidebar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { delay, filter, first, map } from 'rxjs/operators';
import { DynamicDialogcontentDirective } from '@capturum/ui/api';
import { SidebarAction } from '@core/enums/sidebar-actions.enum';
import { Observable } from 'rxjs';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { MenuItemService } from '@shared/services/menu-item.service';

@Component({
  selector: 'app-right-sidebar-int',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightSidebarComponent implements AfterViewInit {
  @Input()
  public title: string;
  @Input()
  public title$: Observable<string>;
  @Input()
  public actions: PageConfigAction[];
  @Input()
  public visible: boolean;
  @Input()
  public showButtons: boolean = true;
  @Input()
  public showSendButton: boolean = false;
  @Input()
  public submitButtonTranslationKey: string = 'button.submit';
  @Input()
  public cancelButtonTranslationKey: string = 'button.cancel';
  @Input()
  public submitButtonDisabled: boolean = false;
  @Input()
  public submitButtonStyleClass: string = 'success';
  @Input()
  public sendButtonStyleClass: string = 'primary';
  @Input()
  public submitButtonIcon: string = 'fas fa-save';

  @Output()
  public onSubmit = new EventEmitter<any>();
  @Output()
  public onCancel = new EventEmitter<any>();
  @Output()
  public visibleChange = new EventEmitter<boolean>();
  @Output()
  public onHide = new EventEmitter<void>();

  @ViewChild(Sidebar, { static: true })
  public sidebar: Sidebar;

  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;
  public activeMenuItemKey$: Observable<string>;
  public dismissable: boolean = true;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private menuItemService: MenuItemService,
    public renderer: Renderer2,
    public config: DynamicDialogConfig,
    @Optional() private dialogRef: DynamicDialogRef,
    public zone: NgZone,
  ) {

    this.activeMenuItemKey$ = this.menuItemService.getActiveMenuItem().pipe(
      filter(Boolean),
      map((menuItem: NavMenuItem) => {
        return `module-${menuItem.key ?? 'manage'}`;
      }), delay(1));
  }

  public ngAfterViewInit(): void {
    if (this.childComponentType) {
      this.loadChildComponent(this.childComponentType);
      this.cd.detectChanges();

      if (this.config && this.config.data) {
        this.title = this.config.header;
        this.title$ = !this.title ? this.config.data.title$ : null;
        this.showButtons = this.config.data.showButtons;
        this.submitButtonStyleClass = this.config.data.submitButtonStyleClass || 'success';
        this.submitButtonIcon = this.config.data.submitButtonIcon || 'fas fa-save';
        this.submitButtonTranslationKey = this.config.data.submitButtonTranslationKey || 'button.submit';
        this.showSendButton = this.config.data.showSendButton;
        if (this.config.dismissableMask !== undefined) {
          this.dismissable = this.config.dismissableMask;
        }
      }

      // Make sure the animation is triggered for opening the sidebar
      setTimeout(() => {
        this.visible = true;
        this.visibleChange.emit(true);
        this.cd.detectChanges();
      }, 50);
    }
  }

  public cancel(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onCancel.emit();
  }

  public submit(): void {
    this.onSubmit.emit();
    if (this.dialogRef) {
      this.dialogRef.close(SidebarAction.submit);
    }
  }

  public hide(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit();

    if (this.childComponentType) {
      // Wait for the animation to finish
      setTimeout(() => {
        this.componentRef.destroy();
        this.dialogRef.destroy();
      }, 300);
    }

  }

  public close(): void {
    this.dialogRef.onClose.pipe(
      filter((action) => action === SidebarAction.close),
      first(),
    );
  }

  public loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;

    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }
}
