import { Pipe, PipeTransform } from '@angular/core';
import { MboCheck } from '../../features/mbo-check/models/mbo-check.model';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'formatMbocheckType'
})
export class FormatMbocheckTypePipe implements PipeTransform {

  constructor(private translatePipe: TranslatePipe) {
  }

  public transform(mboCheck: MboCheck): string {
    return mboCheck.mboCheckFiles[0] ? (mboCheck.mboCheckFiles[0].delivererable?.name ||
      (mboCheck.mboCheckFiles[0].delivererable ?
        this.translatePipe.transform(`base-data.${mboCheck.mboCheckFiles[0].delivererable?.id}`) :
        '-') || '-') : '-';
  }
}
