import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
// Components
import { InputCodeComponent } from './components/input-code/input-code.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormCardComponent } from '@shared/components/form-card/form-card.component';
import { CapturumCardModule } from '@capturum/ui/card';
import { FormlyModule } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { InScreenSubMenuItemComponent } from './components/in-screen-sub-menu-item/in-screen-sub-menu-item.component';
import { InScreenSubMenuComponent } from './components/in-screen-sub-menu/in-screen-sub-menu.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DateTimePipe } from './pipes/date-time.pipe';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { BadgeComponent } from './components/badge/badge.component';
import { InputSwitchTypeComponent } from '@shared/formly/types/input-switch/input-switch.formly-type';
import { IntBaseListComponent } from '@shared/base/int-base-list.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { RoleAccordionsComponent } from '@shared/components/role-accordions/role-accordions.component';
import { ImageComponent } from './formly/types/image/image.component';
import { ModelLogModule } from '@capturum/complete';
import { ModelLogTimelineComponent } from './components/model-log-timeline/model-log-timeline.component';
import { DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { UniqueKeysPipe } from '@shared/pipes/unique-keys.pipe';
import { IsAdminPipe } from '@shared/pipes/is-admin.pipe';
import { ModuleIconPipe } from '@shared/pipes/module-icon.pipe';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { EditorFormlyTypeComponent } from '@shared/formly/types/editor/editor.formly-type';
import { HorizontalFormCardComponent } from '@shared/components/horizontal-form-card/horizontal-form-card.component';
import { PickListComponent } from './components/pick-list/pick-list.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { PickListTableComponent } from './components/pick-list-table/pick-list-table.component';
import { ArrayToTextPipe } from '@shared/pipes/array-to-text.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { BaseDataTranslationPipe } from './pipes/base-data-translation.pipe';
import { CapitalizeFirstPipe } from '@shared/pipes/capitalize-first.pipe';
import { AuthSmsComponent } from '@shared/components/two-factor/auth-sms/auth-sms.component';
import { AuthGoogleComponent } from '@shared/components/two-factor/auth-google/auth-google.component';
import { AuthEmailComponent } from '@shared/components/two-factor/auth-email/auth-email.component';
import { TwoFactorFormComponent } from '@shared/components/two-factor/two-factor-form/two-factor-form.component';
import { AccountDetailItemComponent } from '@shared/components/account-detail-item/account-detail-item.component';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ModuleImagePipe } from './pipes/module-image.pipe';
import { MultiSelectFormlyTypeComponent } from '@shared/formly/types/multi-select/multi-select.formly-type';
import { FindLabelByValuePipe } from './pipes/find-label-by-value.pipe';
import { MoreResultsOverlayComponent } from './components/more-results-overlay/more-results-overlay.component';
import { TooltipModule } from 'primeng/tooltip';
import { ValuesToLabelsPipe } from './pipes/values-to-labels.pipe';
import { HtmlFormlyTypeComponent } from '@shared/formly/types/html/html-formly-type';
import { MailTagListComponent } from './componens/mail-tag-list/mail-tag-list.component';
import { ChoicesInstancePipe } from '@shared/pipes/choice-instance.pipe';
import { ChoiceStatusPipe } from '@shared/pipes/choice-status.pipe';
import { FilterUserRowActionsPipe } from '@shared/pipes/filter-user-row-actions.pipe';
import { ShowRegionsPipe } from './pipes/show-regions.pipe';
import { FormatMbocheckTypePipe } from './pipes/format-mbocheck-type.pipe';

@NgModule({
  entryComponents: [
    ModelLogTimelineComponent,
  ],
  declarations: [
    HtmlFormlyTypeComponent,
    InputCodeComponent,
    InScreenSubMenuItemComponent,
    InScreenSubMenuComponent,
    FormCardComponent,
    MenuButtonComponent,
    DateTimePipe,
    TableFilterComponent,
    BadgeComponent,
    InputSwitchTypeComponent,
    IntBaseListComponent,
    RoleAccordionsComponent,
    RightSidebarComponent,
    ImageComponent,
    ModelLogTimelineComponent,
    UniqueKeysPipe,
    IsAdminPipe,
    ModuleIconPipe,
    EditorFormlyTypeComponent,
    HorizontalFormCardComponent,
    PickListComponent,
    PickListTableComponent,
    ArrayToTextPipe,
    LoaderComponent,
    BaseDataTranslationPipe,
    ModuleImagePipe,
    FilterUserRowActionsPipe,
    CapitalizeFirstPipe,
    AccountDetailItemComponent,
    AuthSmsComponent,
    AuthGoogleComponent,
    AuthEmailComponent,
    TwoFactorFormComponent,
    MoreResultsOverlayComponent,
    MultiSelectFormlyTypeComponent,
    FindLabelByValuePipe,
    ValuesToLabelsPipe,
    MailTagListComponent,
    ChoicesInstancePipe,
    ChoiceStatusPipe,
    ShowRegionsPipe,
    FormatMbocheckTypePipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxPermissionsModule,
    CapturumInputModule,
    KeyFilterModule,
    SidebarModule,
    DropdownModule,
    InputTextModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    CapturumCardModule,
    FormlyModule.forChild(),
    CapturumSharedModule,
    OverlayPanelModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumDropdownModule,
    CapturumInputSwitchModule,
    AccordionModule,
    ModelLogModule,
    DynamicDialogModule,
    CapturumEditorModule,
    CapturumInfoTableModule,
    CapturumCheckboxModule,
    TooltipModule,
  ],
  exports: [
    InputCodeComponent,
    NgxPermissionsModule,
    TranslateModule,
    InScreenSubMenuItemComponent,
    InScreenSubMenuComponent,
    CapturumSharedModule,
    FormCardComponent,
    MenuButtonComponent,
    DateTimePipe,
    TableFilterComponent,
    BadgeComponent,
    RightSidebarComponent,
    FormlyModule,
    RoleAccordionsComponent,
    ModelLogModule,
    DynamicDialogModule,
    UniqueKeysPipe,
    IsAdminPipe,
    ModuleIconPipe,
    CapturumEditorModule,
    HorizontalFormCardComponent,
    PickListComponent,
    PickListTableComponent,
    ArrayToTextPipe,
    LoaderComponent,
    BaseDataTranslationPipe,
    CapitalizeFirstPipe,
    AccountDetailItemComponent,
    AuthSmsComponent,
    AuthGoogleComponent,
    AuthEmailComponent,
    TwoFactorFormComponent,
    ModuleImagePipe,
    FilterUserRowActionsPipe,
    CapturumMultiSelectModule,
    MultiSelectFormlyTypeComponent,
    MoreResultsOverlayComponent,
    HtmlFormlyTypeComponent,
    MailTagListComponent,
    ChoicesInstancePipe,
    ChoiceStatusPipe,
    ShowRegionsPipe,
    FormatMbocheckTypePipe,
  ],
  providers: [
    DatePipe,
    DynamicDialogConfig,
    TranslatePipe,
  ],
})
export class SharedModule {
}
