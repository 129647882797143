<div [class]="activeMenuItemKey$ | async">
  <app-header></app-header>

  <div id="wrapper">
    <app-sidebar></app-sidebar>

    <div class="page-wrapper gray-bg" [class.impersonating]="(isImpersonated$ | async) === true">
      <app-page-header [pageConfig]="pageConfig$ | async"></app-page-header>

      <router-outlet></router-outlet>
    </div>

    <app-impersonate-indicator></app-impersonate-indicator>
  </div>


  <p-confirmDialog header="Confirmation" [closable]="false" #cd>
    <p-footer>
      <cap-button [icon]="cd?.confirmation?.rejectIcon || 'fas fa-times'"
                  [label]="cd?.confirmation?.rejectLabel || ('general.no' | translate)"
                  (click)="cd.reject()"
                  [styleClass]="'secondary ' + cd?.confirmation?.rejectButtonStyleClass">
      </cap-button>

      <cap-button [icon]="cd?.confirmation?.acceptIcon || 'fa fa-check'"
                  [label]="cd?.confirmation?.acceptLabel || ('general.yes' | translate)"
                  (click)="cd.accept()"
                  [styleClass]="'primary ' + cd?.confirmation?.acceptButtonStyleClass">
      </cap-button>
    </p-footer>
  </p-confirmDialog>
</div>
