<div *ngIf="(items.length - 1)  > 0" class="more-results-overlay d-inline" (mouseenter)="op.show($event)"
     (mouseleave)="op.hide()" #moreResultsCounter>
  <div class="more-results-overlay__counter">
    +{{ items.length - 1 }}
  </div>
</div>

<p-overlayPanel #op appendTo="body">
  <ng-template pTemplate>
    <div class="more-results-overlay__overlay">
      <span class="title">
        {{ title }}
      </span>

      <ul>
        <li *ngFor="let item of items">
          {{ item }}
        </li>
      </ul>
    </div>
  </ng-template>
</p-overlayPanel>
