import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '@core/enums/date-format.enum';

@Pipe({
  name: 'appDateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  public transform(value: unknown): string {
    return this.datePipe.transform(value, DateFormat.displayDateTime);
  }

}
