import { Injectable } from '@angular/core';
import { ApiService, ListOptions } from '@capturum/api';
import { ModuleApiModel } from '@capturum/complete';
import { BehaviorSubject, Observable } from 'rxjs';
import { responseData } from '@core/utils/converter.utils';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { Module, TenantModule, TenantModuleFormRequest } from '../models/module.model';
import { NavigationEnd, Router } from '@angular/router';
import { ApiHttpService } from '@capturum/api';
import { Modules, TranslationModules } from '@core/enums/modules.enum';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends ApiService<ModuleApiModel> {
  public currentModule: string;
  public currentModule$: Observable<string>;
  protected endpoint = 'module';
  private moduleSubject = new BehaviorSubject<string>(null);

  constructor(
    apiHttp: ApiHttpService,
    private router: Router,
  ) {
    super(apiHttp);

    this.currentModule = 'intergrip';

    this.currentModule$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        let module: string = Modules.intergrip;

        if (event && event.url) {
          const url = event.url.charAt(0) === '/' ? event.url.substring(1) : event.url;
          module = url.split('/', 1)[0];
        }

        return module;
      }),
      shareReplay(1),
      tap((module) => {
        if (module === 'manage') {
          module = 'intergrip';
        }

        this.currentModule = module;
        this.moduleSubject.next(module);
      }));

    this.currentModule$.subscribe((module) => {
      this.currentModule = module;
    });
  }

  public getCurrentModule(): Observable<string> {
    return this.moduleSubject.asObservable();
  }

  public getUserModules(): Observable<Module[]> {
    return this.index({
      filters: [{
        field: 'intergrip_module',
        value: 1,
        operator: 'equals',
      }],
    }).pipe(responseData, map((modules) => {
      return modules.filter((module) => {
        return module.active === true;
      });
    }));
  }

  public getTenantModules(tenantId: string, options?: ListOptions): Observable<TenantModule[]> {
    return this.apiHttp.get(`/tenant/${tenantId}/module${this.getOptionsQuery(options)}`).pipe(responseData);
  }

  public updateTenantModules(tenantId: string, data: TenantModuleFormRequest): Observable<any> {
    return this.apiHttp.put(`/tenant/${tenantId}/module`, data);
  }

  public transformToTreeStructure(modules: Module[]): any {
    let childModuleIds: string[] = [];

    return modules.map((module) => {
      let moduleData = { ...module };

      let childModules = modules.filter((module2) => module2.parent_id === module.id);
      childModuleIds = [...childModuleIds, ...childModules.map(({ id }) => id)];
      childModules = this.transformToTreeStructure(childModules);

      if (childModules) {
        moduleData = { ...moduleData, children: childModules };
      }

      return moduleData;
    }).filter((module) => childModuleIds.indexOf(module.id) === -1);
  }
}
