import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageConfig } from '@core/models/page-config.model';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public pageConfig$: Observable<PageConfig>;
  private pageConfig = new BehaviorSubject<PageConfig>(null);

  constructor() {
    this.pageConfig$ = this.pageConfig.asObservable();
  }

  public setPageConfig(config: PageConfig): void {
    this.pageConfig.next(config);
  }

  public patchPageConfig(config: Partial<PageConfig>): void {
    this.pageConfig.next({ ...this.pageConfig.getValue(), ...config });
  }
}
