<cap-card>
  <ng-template capTemplate="header">
    <span class="header">
      <i *ngIf="icon" [class]="icon"></i><span class="card__title">{{ title }}</span>
    </span>
  </ng-template>

  <ng-template capTemplate="content">
    <div [class.form-card__form--disabled]="form && form.disabled">
      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
    </div>

    <ng-content></ng-content>
  </ng-template>
</cap-card>
