<form [formGroup]="form" *ngIf="form">
  <div class="two-factor-form">
    <div class="body-part mb-2">
      <h5>1. {{ 'auth.2fa.types.select' | translate }}</h5>

      <cap-dropdown
        [style]="{ width: '100%' }"
        [options]="types$ | async"
        formControlName="method"
        [placeholder]="'auth.2fa.types.select' | translate"
        (change)="changeMethodAuth($event.value)">
        <ng-template capTemplate="item" let-item>
          {{ item.label | translate }}
        </ng-template>

        <ng-template capTemplate="selectedItem" let-item>
          {{ item.label | translate }}
        </ng-template>
      </cap-dropdown>
    </div>

    <ng-container [ngSwitch]="controls.method.value">
      <app-auth-google
        *ngSwitchCase="TwoFactorMethod.google">
      </app-auth-google>

      <app-auth-email
        *ngSwitchCase="TwoFactorMethod.email"
        (emailCodeEvent)="showAndFocusInputCode()">
      </app-auth-email>

      <app-auth-sms
        *ngSwitchCase="TwoFactorMethod.sms"
        (smsCodeEvent)="showAndFocusInputCode()">
      </app-auth-sms>
    </ng-container>

    <div [style.visibility]="showCode ? 'visible' : 'hidden'">
      <div class="mt-5">
        <div class="body-part">
          <h5>
            {{ controls.method.value === TwoFactorMethod.google ? '4' : '3' }}
            . {{ 'auth.2fa.code.title' | translate }}
          </h5>

          <p>
            {{ 'auth.2fa.' + controls.method.value + '.code.description' | translate }}
          </p>
        </div>
      </div>

      <div class="input-codes">
        <app-input-code formControlName="key"></app-input-code>
      </div>
    </div>
  </div>
</form>
