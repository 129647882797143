import { Pipe, PipeTransform } from '@angular/core';
import { Region } from '../../features/regions/models/region.model';

@Pipe({
  name: 'showRegions'
})
export class ShowRegionsPipe implements PipeTransform {

  public transform(regions: Region[]): string {
    return regions?.length > 0 ? regions.map(region => region.name).join(', ') : '-';
  }
}
