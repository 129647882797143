import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';

@Component({
  selector: 'app-auth-email',
  templateUrl: './auth-email.component.html',
})
export class AuthEmailComponent implements OnInit {
  @Output()
  public emailCodeEvent: EventEmitter<boolean> = new EventEmitter();

  public emailForm: FormGroup;
  public emailCode: boolean;
  public emailSent: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: TwoFactorAuthService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });

    this.authService.setupEmail().subscribe(({ identifier }) => {
      this.emailForm.setValue({ email: identifier });
      this.cdr.markForCheck();
    });
  }

  public sendEmail(): void {
    this.authService.register('email', this.emailForm.value.email).subscribe(() => {
      this.emailSent = true;
      this.emailCodeEvent.emit(true);
    });
  }
}
