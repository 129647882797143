import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input()
  public icon: string;
  @Input()
  public label: string;
  @Input()
  public severity: 'success' | 'warning' | 'danger' | 'default' = 'success';
}
