import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValueAccessorBase } from '@capturum/ui/api';
import { Subscription } from 'rxjs';
import { CapturumInputComponent } from '@capturum/ui/input';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(InputCodeComponent)],
})
export class InputCodeComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
  @Input()
  public inputCodeLength: number = 6;
  @Output()
  public onInitialized = new EventEmitter<boolean>();
  @ViewChildren(CapturumInputComponent, { read: ElementRef })
  public inputs: QueryList<ElementRef>;

  public inputsForm: FormGroup;
  private subs: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.inputNumber();
    this.subs = this.inputsArray.valueChanges.subscribe(() => {
      this.value = this.inputsArray.value.join('');
    });
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public writeValue(value: string): void {
    if (value) {
      super.writeValue(value);
    } else {
      this.inputsArray.reset();
    }
  }

  public focusOnNextInput(index: number, value: string): void {
    if (value.trim() !== '') {
      const currentInputElement = this.inputs
        .toArray()
        [index].nativeElement.querySelector('input');

      if (currentInputElement.value.length > 1) {
        currentInputElement.value = currentInputElement.value[0];
      }

      if (this.inputs.toArray()[index + 1] && value !== '') {
        this.inputs
          .toArray()
          [index + 1].nativeElement.querySelector('input')
          .focus();
      } else {
        currentInputElement.blur();
      }
    }
  }

  // @ts-ignore
  public get inputsArray(): FormArray {
    return this.inputsForm.get('myCode') as FormArray;
  }

  public focus(): void {
    this.inputs
      .toArray()
      [0].nativeElement.querySelector('input')
      .focus();
  }

  private inputNumber(): void {
    this.inputsForm = this.formBuilder.group({
      myCode: this.formBuilder.array([]),
    });

    for (let i = 0; i < this.inputCodeLength; i++) {
      this.inputsArray.push(new FormControl(null, [Validators.required]));
    }
  }
}
