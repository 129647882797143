<div class="col-12">
  <form class="login-form">
    <div class="title">
      <span class="text">{{ 'auth.2fa.reset-two-factor' | translate }}</span>
    </div>

    <div class="two-factor-message mb-5" *ngIf="message$ | async as message; else loader">
      <div class="two-factor-message__title">
        <ng-container *ngIf="!isError">
          <i class="fas fa-check-circle"></i>&nbsp;{{ 'toast.success.title' | translate }}
        </ng-container>

        <ng-container *ngIf="isError">
          <i class="fas fa-times-circle"></i>&nbsp;{{ 'toast.error.title' | translate }}
        </ng-container>
      </div>

      <div class="two-factor-message__body">
        {{ message | translate }}
      </div>
    </div>

    <ng-template #loader>
      <app-loader styleClass="my-5 py-5"></app-loader>
    </ng-template>

    <cap-button styleClass="primary" class="text-right" [label]="'auth.forgot-password.confirmed.back' | translate"
                [routerLink]="[routes.login]"></cap-button>
  </form>
</div>
