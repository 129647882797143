import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Locale from '@capturum/auth/lib/locale.interface';
import { ApiService, ListOptions } from '@capturum/api';
import { map } from 'rxjs/operators';
import User from '@capturum/auth/lib/user.interface';

@Injectable({
  providedIn: 'root'
})
export class LocaleService extends ApiService<Locale> {
  protected endpoint = 'locale';

  public getLocales(options?: ListOptions): Observable<Locale[]> {
    return super.index(options).pipe(
      map(responseData => responseData.data)
    );
  }

  public saveCurrentUserLocale(userId: number | string, localeId: number | string): Observable<User> {
    return this.apiHttp.put(`/user/${userId}`, { locale_id: localeId }).pipe(
      map((response: any) => response.data),
    );
  }
}
