import { Injectable } from '@angular/core';
import {
  ModelLog,
  PermissionService,
  User,
  UserService as CompleteUserService,
} from '@capturum/complete';
import { ModelLogFactory } from '@core/factories/model-log.factory';
import { map } from 'rxjs/operators';
import { ApiHttpService, ApiIndexResult, ListOptions, Meta } from '@capturum/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PageConfigAction } from '@core/models/page-config.model';
import { TranslateService } from '@ngx-translate/core';
import { FormattedRole, Role } from '../../role/models/role.model';
import { UserModel } from '../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@capturum/ui/api';
import { ImpersonateState } from './impersonate.state';
import { LoadingService } from '@capturum/ui/loader';
import { AuthService } from '../../auth/services/auth.service';
import { RoleType } from '../../role-type/models/role-type.model';
import { Store } from '@ngxs/store';
import { ImpersonateUser, StopImpersonateUser } from '@core/state/user/user.actions';
import { UserStateModel } from '@core/state/user/user.state';
import Locale from '@capturum/auth/lib/locale.interface';
import AuthUser from '@capturum/auth/lib/user.interface';
import { environment } from '@environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CompleteUserService {
  public userChanged$: Observable<boolean>;
  public isImpersonated$: Observable<boolean>;

  protected endpoint = 'user';

  private pageConfigActions: Record<string, PageConfigAction> = {
    sendAccountData:
      {
        label: this.translateService.instant('intergrip.user.send-account-info'),
        icon: 'fas fa-envelope',
        callback: () => {
          // @TODO: add function here
        },
      },
    loginAsUser: {
      label: this.translateService.instant('intergrip.user.login-as-user'),
      icon: 'fas fa-sign-in',
      callback: () => {
        // @TODO: add function here
      },
    },
    sendReset2fa: {
      label: this.translateService.instant('intergrip.user.send-reset-2fa'),
      icon: 'fas fa-lock-alt',
      callback: () => {
        // @TODO: add function here
      },
    },
    duplicateUser: {
      label: this.translateService.instant('intergrip.entity.duplicate', {
        entity: this.translateService.instant('intergrip.user.entity_name'),
      }),
      icon: 'fas fa-lock-alt',
      callback: () => {
        // @TODO: add function here
      },
    },
  };
  private userChanged = new BehaviorSubject<boolean>(true);

  constructor(
    apiHttp: ApiHttpService,
    private translateService: TranslateService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private impersonateState: ImpersonateState,
    private loadingService: LoadingService,
    private store: Store,
    private http: HttpClient
  ) {
    super(apiHttp, authService);
    this.userChanged$ = this.userChanged.asObservable();
  }

  public index<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return super.index(options);
  }

  public indexTrashed<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return this.apiHttp.get(`/${this.endpoint}/trashed/${this.getOptionsQuery(options)}`);
  }

  public indexScoped<T = UserModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    const indexOptions: ListOptions = {
      ...options,
      filters: [...options.filters],
    };

    return super.index(indexOptions);
  }

  public getConfigActionItem(key: string, callback?: (...args: any) => void): PageConfigAction {
    return {
      ...this.pageConfigActions[key],
      callback: callback ? callback : this.pageConfigActions[key].callback,
    };
  }

  public setRolePermission(roleId: string): Observable<string> {
    return this.apiHttp.put(`/${this.endpoint}/${this.authService.getUser().id}/favorite/role`, { id: roleId });
  }

  public getModelLogs(id: string, page: number = 1, perPage: number = 10): Observable<{ data: Record<string, ModelLog[]>, meta: Meta }> {
    return this.apiHttp.get<{ data: ModelLog[], meta: Meta }>(`/${this.endpoint}/${id}/model-log${this.getOptionsQuery({
      include: ['user'],
      page,
      perPage,
    })}`).pipe(
      map((response) => {
        const logs: Record<string, ModelLog[]> = {};
        const roleKeys = ['current_role_id', 'current_role_user_id'];

        response.data.forEach((log, index) => {
          const roleKey = Object.keys(log.diffs).find((key) => roleKeys.indexOf(key) !== -1);

          if (roleKey) {
            logs[index] = [{
              ...log,
              model_loggable_type: 'APP\\User',
              diffs: {
                current_role_id: {
                  new: this.translateService.instant('intergrip.user.switched-role'),
                  old: '-',
                },
              },
            }];
          } else {
            logs[index] = [{ ...log, model_loggable_type: 'APP\\User' }];
          }
        });

        return { data: logs, meta: response.meta };
      }),
    );
  }

  public getHistory(userId: string, page: number, perPage: number = 5): Observable<Record<string, ModelLog[]>> {
    let modelLogs = {};

    for (let i = 0; i < perPage; i++) {
      modelLogs = { ...modelLogs, ...ModelLogFactory.create() };
    }

    return of(modelLogs);
  }

  public formatRoles(roles: Role[]): Map<string, FormattedRole> {
    const roleTypeMap = new Map();

    roles.forEach((role: Role) => {
      if (role.role_type_id) {
        roleTypeMap.set(role.roleType.name, [
          ...(roleTypeMap.get(role.roleType.name) ? roleTypeMap.get(role.roleType.name) : []),
          {
            id: role.id,
            tenant: role.tenant.name,
            module: role.module,
            schoolYear: role.schoolYear,
            is_deletable: this.hasHigherRoleRank(
              this.store.selectSnapshot((state: { userState: UserStateModel }) => state.userState.user.currentRoleType),
              [role],
            ),
          },
        ]);
      }
    });

    return roleTypeMap;
  }

  public addRole(data: { role_id: string, user_id: string, school_year_id: string, module_id: string }): Observable<Role> {
    return this.apiHttp.post(`/role-user`, data);
  }

  public sendAccountInfo(userId: string): Observable<void> {
    return this.apiHttp.post(`/auth/${this.endpoint}/send-activate-email`, { user_id: userId });
  }

  public impersonate(userId: string): void {
    this.loadingService.toggleLoading(true);

    this.apiHttp.get(`/auth/${this.endpoint}/${userId}/impersonate`).subscribe(({ token, user }) => {
      this.store.dispatch(new ImpersonateUser(user));
      this.setLoggedInUser(token, user);
    }, err => {
      this.loadingService.hideLoader();
    });
  }

  public stopImpersonate(): void {
    this.loadingService.toggleLoading(true);

    this.apiHttp.get(`/auth/stop-impersonate`).subscribe(({ token, user }) => {
      this.store.dispatch(new StopImpersonateUser());
      this.setLoggedInUser(token, user);
    }, () => {
      this.loadingService.hideLoader();
    });
  }

  public restore(userId: string): Observable<void> {
    return this.apiHttp.put(`/user/${userId}/restore`, { user_id: userId });
  }

  public updateUserState(): void {
    this.userChanged.next(true);
  }

  public export(): Observable<HttpResponse<Blob>> {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    return this.http.get(`${environment.baseUrl}/user/export`,
      {
        observe: 'response',
        responseType: 'blob',
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${token}`)
         });
  }

  /**
   * Compare own roles to user roles for a higher rank
   *
   * @param currentUserRoleType
   * @param userRoles: Role[]
   *
   * @return boolean
   */
  public hasHigherRoleRank(currentUserRoleType: RoleType, userRoles: Role[]): boolean {
    const highestOwnRank = currentUserRoleType?.rank;
    const highestUserRank = Math.min.apply(Math, userRoles.map(role => role?.roleType?.rank));

    // Return true if the current user has a higher or equal rank (lower number = higher rank)
    return highestOwnRank <= highestUserRank;
  }

  public updateUserLocalStorage(locale: Locale): void {
    const localStorageUser = localStorage.getItem('user');
    const updatedUser: AuthUser = JSON.parse(localStorageUser);
    updatedUser.locale = locale;
    updatedUser.locale_id = locale.id;
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }

  public legacyKey(): Observable<{ data: { legacyKey: string, legacyBaseUrl: string } }> {
    return this.apiHttp.get(`/legacy/key`);
  }

  private setLoggedInUser(token: string, user: User): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    this.permissionService.loadPermissions().subscribe(() => {
      this.userChanged.next(true);

      this.toastService.success(
        this.translateService.instant('impersonate.logged-in-as.toast.title'),
        this.translateService.instant('impersonate.logged-in-as.toast.message', { user: user.name }),
      );

      this.loadingService.hideLoader();

      this.router.navigate(['/']).then(() => {
        this.router.navigate(['./'], { relativeTo: this.route });
      });
    });
  }
}
