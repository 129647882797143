import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterMatchMode, FilterType } from '@capturum/ui/api';
import { FilterMetadata } from 'primeng/api';
import { FilterConfig } from '@core/models/filter-config.model';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent {
  @Input()
  public filters: FilterConfig[];
  @Input()
  public activeFilters: Record<string, FilterMetadata>;

  @Output()
  public onFilter = new EventEmitter<{ field: string, value: any, matchMode: string }>();
  @Output()
  public onSearch = new EventEmitter<string>();
  @Output()
  public onReset = new EventEmitter<void>();

  public filterType = FilterType;

  public setFilter(value: any, field: string, matchMode: string, isSearch?: boolean): void {
    if (isSearch) {
      this.onSearch.emit(value);
    } else {
      this.onFilter.emit({ field, value, matchMode });
    }
  }

  public setInputSwitchFilter(checked: boolean, field: string): void {
    this.setFilter('null', field, checked ? FilterMatchMode.NOT_EQUALS : FilterMatchMode.EQUALS, false);
  }

  public reset(): void {
    this.onReset.emit();
  }
}
