import { FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomFormlyConfig extends FormlyConfig {
  constructor(private translateService: TranslateService) {
    super();
  }

  public getValidatorMessage(name: string): string | ((error: any, field: FormlyFieldConfig) => (string | Observable<string>)) {
    let translation = this.translateService.instant(`validation-errors.${name}`);

    if (translation.includes('[[')) {
      translation = this.translateService.instant(`Intergrip.validation-errors.${name}`);
    }

    return translation;
  }
}
