<div class="body-part">
  <h5>2. {{ 'auth.2fa.google.title' | translate }}</h5>
  <p [innerHTML]="'auth.2fa.google.description' | translate">
  </p>
</div>

<div class="body-part mt-5">
  <h5>3. {{ 'auth.2fa.google.qr.title' | translate }}</h5>
  <p>
    {{ 'auth.2fa.google.qr.description' | translate }}
  </p>

  <div class="qr-code text-center" *ngIf="qrcode$ | async as qrCodeData">
    <img class="my-3" [src]="qrCodeData.qrCode" alt="QR code"/>

    <div class="small-description">
      {{ 'intergrip.profile.2fa.google.fail-scan-message' | translate }}

      <br />

      <strong>{{ qrCodeData.secret }}</strong>
    </div>
  </div>
</div>
