import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormattedRole } from '../../../features/role/models/role.model';

@Component({
  selector: 'app-role-accordions',
  templateUrl: './role-accordions.component.html',
  styleUrls: ['./role-accordions.component.scss'],
})
export class RoleAccordionsComponent {
  @Output()
  public deleteRole = new EventEmitter<{ index: number, role: FormattedRole }>();

  @Input()
  public roleType: { key: string, value: FormattedRole[] };

  @Input()
  public isDeletable: boolean;
}
