import { Component } from '@angular/core';
import { MenuItemService } from '@shared/services/menu-item.service';
import { Observable } from 'rxjs';
import { NavMenuItem } from '@core/models/nav-menu-item.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public activeMenuItem$: Observable<NavMenuItem>;

  constructor(private menuItemService: MenuItemService) {
    this.activeMenuItem$ = this.menuItemService.getActiveMenuItem();
  }
}
