import { Pipe, PipeTransform } from '@angular/core';
import { UserModules } from '@core/enums/user-modules.enum';

@Pipe({
  name: 'moduleIcon',
})
export class ModuleIconPipe implements PipeTransform {
  // TODO: place correct icons here once available from PM
  public moduleIconMap: Record<UserModules, string> = {
    [UserModules.INTERGRIP]: 'fab fa-accessible-icon',
    [UserModules.ADDRESS]: 'fas fa-blind',
    [UserModules.MODEL_LOG]: 'fas fa-deaf',
    [UserModules.FAVORITE]: 'fas fa-tty',
    [UserModules.GRIP_OVERSTAP_VO]: 'fas fa-sign-language',
    [UserModules.GRIP_DOORSTROOMDOSSIER]: 'fas fa-universal-access',
    [UserModules.GRIP_MONITOR_US]: 'fas fa-wheelchair',
    [UserModules.GRIP_OVERSTAP_MBO]: 'fas fa-cat',
    [UserModules.GRIP_LOB_VOORLICHTINGEN]: 'fas fa-dove',
    [UserModules.GRIP_LOB_ACTIVITEITEN]: 'fas fa-fish',
    [UserModules.GRIP_LOB_PORTFOLIO]: 'fas fa-kiwi-bird',
  };

  public transform(value: string): unknown {
    return this.moduleIconMap[value];
  }
}
