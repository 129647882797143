export enum Modules {
  intergrip = 'manage',
  transferVo = 'transfer-vo',
  transferMbo = 'transfer-mbo',
  ddd = 'ddd',
  lobdossier = 'lobdossier',
  lobActivities = 'lobactivities',
  activity = 'activity',
  assignment = 'assignment',
  lobVoorlichtingen = 'zoekjouwstudie',
  monitorus = 'monitorus',
}

export enum ModuleIconMap {
  vombo = 'fas fa-sync',
  mbombo = 'fas fa-sync',
  ddd = 'fas fa-file-user',
  lob = 'fas fa-file-user',
  lobdossier = 'fas fa-file-user',
  lobactivities = 'fas fa-file-user',
  zoekjouwstudie = 'fas fa-file-user',
  monitorus = 'fas fa-file-user',
}

export enum TranslationModules {
  manage = 'intergrip',
  transfer  = 'transfer',
}
