import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from '@capturum/api';
import { environment } from '@environments/environment';
import { AuthModule } from './features/auth/auth.module';
import { AuthModule as CompleteAuthModule } from '@capturum/auth';
import { LoginModule } from '@capturum/login';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { SharedModule } from '@capturum/shared';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CompleteModule, ErrorMessageInterceptor, TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { CommonModule } from '@angular/common';

// Components
import { AppComponent } from './app.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormModule } from '@capturum/formly';
import { AuthService } from './features/auth/services/auth.service';
import { IntLoginService } from './features/auth/services/int-login.service';
import { FormlyConfig, FormlyModule } from '@ngx-formly/core';
import { InputSwitchTypeComponent } from '@shared/formly/types/input-switch/input-switch.formly-type';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { ImageComponent } from '@shared/formly/types/image/image.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EditorFormlyTypeComponent } from '@shared/formly/types/editor/editor.formly-type';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CustomPublicTranslationResolver } from '@shared/resolvers/custom-public-translation.resolver';
import { MultiSelectFormlyTypeComponent } from '@shared/formly/types/multi-select/multi-select.formly-type';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { HtmlFormlyTypeComponent } from '@shared/formly/types/html/html-formly-type';
import { CustomFormlyConfig } from './formly-field';
import { IntTranslateParser } from '@shared/translate/int-translate-parser';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';

function onAuthError(): void {
  window.location.href = `auth/login`;
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return `[[ ${params.key} ]]`;
  }
}

function intPasswordValidator(control: AbstractControl): ValidationErrors {
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$');

  return !control.value || passwordRegex.test(control.value) ? null : { password: true };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    CommonModule,
    CapturumInputModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
      parser: {
        provide: TranslateParser,
        useClass: IntTranslateParser,
      },
    }),
    NgxPermissionsModule.forRoot(),
    CompleteAuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: '/auth/login',
      authService: AuthService,
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    LoginModule.forRoot({
      environment,
      productName: 'Intergrip',
      redirectAfterLogin: '/admin/config',
      loginService: IntLoginService,
      passwordValidator: intPasswordValidator,
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot({
        indexedDbModels: [],
        databaseName: 'complete-db',
        version: 1,
      },
      NgxPermissionsService,
      NgxPermissionsModule,
    ),
    CoreModule,
    CapturumButtonModule,
    CapturumToastModule,
    CapturumInputSwitchModule,
    CapturumMultiSelectModule,
    FormModule,
    CapturumEditorModule,
    FormlyModule.forRoot({
      types: [
        {
          component: InputSwitchTypeComponent,
          name: 'intergripInputswitch',
          wrappers: ['cap-form-field'],
        },
        {
          component: ImageComponent,
          name: 'image',
          wrappers: ['cap-form-field'],
        },
        {
          component: EditorFormlyTypeComponent,
          name: 'editor',
          wrappers: ['cap-form-field'],
        },
        {
          component: MultiSelectFormlyTypeComponent,
          name: 'multi-select',
          wrappers: ['cap-form-field'],
        },
        {
          component: HtmlFormlyTypeComponent,
          name: 'html',
          wrappers: ['cap-form-field'],
        },
      ],
    }),
    ConfirmDialogModule,
  ],
  providers: [
    {
      provide: FormlyConfig,
      useClass: CustomFormlyConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    CustomPublicTranslationResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
