import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueKeys',
})
export class UniqueKeysPipe implements PipeTransform {
  public transform(values: any[], key: string): string[] {
    if (values) {
      return Array.from(new Set<string>(
        values.map((value) => {
          const keys = key.split('.');
          let returnValue = value;

          for (const valueKey of keys) {
            returnValue = returnValue[valueKey];
          }

          return returnValue;
        }),
      ));
    }

    return [];
  }
}
