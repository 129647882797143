import { TranslateDefaultParser } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ModuleService } from '../../features/module/services/module.service';
import { TranslationModules } from '@core/enums/modules.enum';
import { Router } from '@angular/router';

@Injectable()
export class IntTranslateParser extends TranslateDefaultParser {
  constructor(public moduleService: ModuleService, private route: Router) {
    super();
  }

  public getValue(target: any, key: string): any {
    const event = { url: this.route.url };
    let currentModule: string;

    if (event && event.url) {
      const url = event.url.charAt(0) === '/' ? event.url.substring(1) : event.url;
      currentModule = url.split('/', 1)[0];
    }

    let prefixedKey = key;
    const moduleKey = key.split('.', 1)[0];

    if (moduleKey === 'manage') {
      prefixedKey = prefixedKey.replace('manage', TranslationModules.manage);
    } else if (TranslationModules[moduleKey]) {
      prefixedKey = prefixedKey.replace(moduleKey, currentModule);
    } else if (moduleKey && moduleKey !== 'intergrip' && TranslationModules[moduleKey]) {
      prefixedKey = `${TranslationModules[currentModule]}.${key}`;
    }

    return super.getValue(target, prefixedKey);
  }
}

