import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@shared/services/layout.service';
import { Observable } from 'rxjs';
import { PageConfig } from '@core/models/page-config.model';
import { delay, filter, map } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';
import { MenuItemService } from '@shared/services/menu-item.service';
import { NavMenuItem } from '@core/models/nav-menu-item.model';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicLayoutComponent {
  @Select(UserState.isImpersonating)
  public isImpersonated$: Observable<boolean>;
  public pageConfig$: Observable<PageConfig>;
  public activeMenuItemKey$: Observable<string>;

  constructor(
    private translateService: TranslateService,
    private layoutService: LayoutService,
    private menuItemService: MenuItemService,
  ) {
    this.pageConfig$ = this.layoutService.pageConfig$.pipe(
      filter<PageConfig>(Boolean),
      delay(0),
    );

    this.activeMenuItemKey$ = this.menuItemService.getActiveMenuItem().pipe(
      filter(Boolean),
      map((menuItem: NavMenuItem) => {
        return `module-${menuItem.key ?? 'manage'}`;
      }), delay(1));

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
}
