<div class="page-header" *ngIf="pageConfig">
  <div class="d-flex justify-content-between w-100">
    <div class="icon-title-wrapper">
      <ng-container *ngIf="pageConfig.backUrl">
        <div class="icon">
          <i [attr.data-test]="'header-previous-link'" class="fas fa-arrow-left header-previous-link"
             [routerLink]="[pageConfig.backUrl]" [queryParams]="pageConfig.backUrlQueryParams"></i>
        </div>
      </ng-container>

      <div class="page-header-title">
        <span class="header-title">
        {{ pageConfig.title }}
          </span>
      </div>
    </div>

    <div class="buttons d-flex" *ngIf="pageConfig">
      <ng-container *ngFor="let button of pageConfig.buttons">
        <ng-container *ngIf="!button.hide || (button.hide | async) === false">
          <div class="button ml-2" *ngxPermissionsOnly="button.permissions">
            <cap-button
              [label]="button.label | translate : button.translateParams"
              [icon]="button.icon"
              [styleClass]="button.styleClass"
              (click)="button.callback()"
              [attr.data-test]="button?.dataTestAttribute">
            </cap-button>
          </div>
        </ng-container>
      </ng-container>

      <app-menu-button [menuItems]="pageConfig?.actions"></app-menu-button>
    </div>
</div>
