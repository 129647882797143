import { TwoFactorMethod } from '@core/enums/helpers/two-way-auth.enum';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapItem, ToastService } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { InputCodeComponent } from '@shared/components/input-code/input-code.component';
import { TwoFactorAuthService } from '../../../../features/auth/services/two-factor-auth.service';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-two-factor-form',
  templateUrl: './two-factor-form.component.html',
  styleUrls: ['./two-factor-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoFactorFormComponent implements OnInit, OnDestroy {
  @Output()
  public onChange = new EventEmitter();

  @ViewChild(InputCodeComponent, { static: false })
  public inputCodeComponent: InputCodeComponent;

  public TwoFactorMethod: typeof TwoFactorMethod = TwoFactorMethod;
  public showCode: boolean = false;
  public sidebarToggle: boolean;

  public types$: Observable<MapItem[]>;
  public form: FormGroup;
  public controls: {
    method: AbstractControl,
    key: AbstractControl,
  };

  private subSink = new SubSink();

  constructor(
    private authService: TwoFactorAuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
  ) {
    this.types$ = this.authService.list2faMethods().pipe(
      shareReplay(),
    );
  }

  public ngOnInit(): void {
    this.form = this.getForm();

    this.controls = {
      method: this.form.get('method'),
      key: this.form.get('method'),
    };

    this.subSink.add(
      this.form.valueChanges.subscribe((value) => {
        this.onChange.emit(value);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public changeMethodAuth(method: TwoFactorMethod): void {
    this.form.get('key').setValue(null);

    this.showCode = method === TwoFactorMethod.google;
  }

  public resetForm(): void {
    this.showCode = false;
    this.form.reset();
  }

  public getForm(): FormGroup {
    return this.formBuilder.group({
      key: [null, [Validators.required, Validators.minLength(6)]],
      method: [null, Validators.required],
    });
  }

  public showAndFocusInputCode(): void {
    this.showCode = true;
    this.form.get('key').setValue(null);
    // This setTimeout is needed because the focus doesn't work when the component does not exist yet.
    // And we don't know when the component's inputs are available
    setTimeout(() => {
      this.inputCodeComponent.focus();
    }, 50);
  }
}
