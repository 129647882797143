import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for password
 *
 * @param control
 */
export function passwordValidator(control: FormControl): ValidationErrors {
  /**
   * Standard password validation regular expression
   * Length must be at least 8 characters
   * It has to contain at least 1 capital letter
   * It has to contain at least 1 number
   */
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

  return !control.value || passwordRegex.test(control.value)  ? null : { password: true };
}
