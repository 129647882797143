<div class="sidebar">
  <div class="menu" *ngIf="activeMenuItem$ | async as activeMenuItem">
    <h2>{{ ((activeMenuItem.key + '.module_alias' | setting : '') || (activeMenuItem.title | translate)) }}</h2>

    <div class="list" *ngIf="activeMenuItem.children">
      <ng-container *ngFor="let item of activeMenuItem.children">
        <div [attr.data-test]="item?.data_test" class="item" [routerLink]="[item.routerUrl]" routerLinkActive="active" *ngxPermissionsOnly="item.permissions">
          <i [class]="item.icon"></i> {{ item.title | translate }}
        </div>
      </ng-container>
    </div>

    <div class="banner">
      <div class="logo">
        <a routerLink="/">
          <img [src]="'general.tenant_logo' | setting : ''"
               [alt]="'general.tenant_name' | setting : ''" />
        </a>
      </div>
    </div>
  </div>
</div>
