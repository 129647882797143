import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TwoFactorAuthService } from '../services/two-factor-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InputCodeComponent } from '@shared/components/input-code/input-code.component';

@Component({
  selector: 'app-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent implements OnInit, AfterViewInit {
  @ViewChild(InputCodeComponent)
  public inputCodeComponent: InputCodeComponent;

  public inputCodeControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(6)]);
  public method: string;

  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    this.method = this.route.snapshot.queryParams.method;
  }

  public ngAfterViewInit(): void {
    this.inputCodeComponent.focus();
  }

  public onSubmit(): void {
    const params = this.route.snapshot.queryParams;

    this.twoFactorAuthService.login({
      user_id: params.userId,
      method: params.method,
      key: this.inputCodeControl.value,
    }).subscribe((response) => {
      localStorage.setItem('token', response.token);
      localStorage.setItem('user', JSON.stringify(response.user));

      this.router.navigate(['/']);
    });
  }
}
