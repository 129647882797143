import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PageRoute } from '@core/enums/general/routes.enum';
import { UserService } from '../../../user/services/user.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss'],
})

export class LegacyComponent {
  public url: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authService: AuthService,
  ) {
    console.log(this.route.routeConfig.path);

    this.userService.legacyKey().subscribe(response => {
      this.setUrl(response.data.legacyKey, response.data.legacyBaseUrl, this.route.routeConfig.path);
      window.addEventListener('message', event => {
        if (event.origin === response.data.legacyBaseUrl && event.data === 'logout') {
          this.authService.logout().subscribe(() => {
            this.router.navigate([PageRoute.login]);
          });
        }
      });
    });
  }

  private setUrl(key: string, baseUrl: string, url: string): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl + '/auth/legacy/key?endpoint=' + url + '&key=' + key);
  }
}
