import { ModelLog } from '@capturum/complete';
import * as faker from 'faker';

export class ModelLogFactory {
  public static create(): Record<string, ModelLog[]> {
    return {
      [faker.date.past().toDateString()]: [{
        id: faker.random.uuid(),
        model_loggable_type: 'App\\MODEL\\User',
        original_model: `{ "name": "${faker.name.firstName()}" }`,
        new_model: `{ "name": "${faker.name.firstName()}" }`,
        user: {
          id: '1',
          name: faker.name.firstName(),
          email: faker.internet.email(),
          roles: [],
        },
        reason: faker.random.words(3),
        reason_type_base_data_value_id: faker.random.uuid(),
        created_at: faker.date.past(),
        created_on: faker.date.past(),
        entity_key: 'user',
        action: 'updated',
        diffs: {
          name: {
            old: faker.name.firstName(),
            new: faker.name.firstName(),
          },
          age: {
            old: faker.random.number(99),
            new: faker.random.number(99),
          },
        },
      }],
    };
  }
}
