import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-in-screen-sub-menu-item',
  templateUrl: './in-screen-sub-menu-item.component.html',
  styleUrls: ['./in-screen-sub-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InScreenSubMenuItemComponent {
  @Input()
  public icon: string;
  @Input()
  public title: string;
  @Input()
  public subTitle: string;
  @Input()
  public subtitleTemplate: TemplateRef<any>;
  @Input()
  public styleClass: string;
}
