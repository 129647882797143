import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceStudent, TodoStudent } from '../../features/todo-student/models/todo-student.model';

@Pipe({
  name: 'choiceInstance',
})
export class ChoicesInstancePipe implements PipeTransform {
  public transform(student: TodoStudent): string {
    const bestChoiceStudent: ChoiceStudent = student.choiceStudents.find(choiceStudent => choiceStudent.bestChoice === true);

    if (bestChoiceStudent?.choiceable?.school?.name) {
      return bestChoiceStudent.choiceable.school.name;
    } else if (bestChoiceStudent?.choiceable?.name) {
      return bestChoiceStudent.choiceable.name;
    } else {
      return '-';
    }
  }
}
