import { Pipe, PipeTransform } from '@angular/core';
import { RoleModel } from '../../features/user/models/user.model';

@Pipe({
  name: 'isAdmin',
})
export class IsAdminPipe implements PipeTransform {
  public transform(roles: RoleModel[]): boolean {
    return roles && roles.length && roles.some((role) => role.key === 'admin');
  }
}
