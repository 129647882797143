<p-sidebar [(visible)]="visible" [dismissible]="dismissable" styleClass="right-sidebar" position="right" [showCloseIcon]="false" (onHide)="hide()">
  <div class="right-sidebar__header" [class]="activeMenuItemKey$ | async" *ngIf="title">
      {{ title }}

    <i class="fas fa-times" (click)="hide()"></i>
  </div>

  <div class="right-sidebar__header" [class]="activeMenuItemKey$ | async" *ngIf="!title">
    {{ title$ | async }}

    <i class="fas fa-times" (click)="hide()"></i>
  </div>

  <div class="right-sidebar__content">
    <ng-container *ngIf="childComponentType; else default">
      <ng-template capDynamicDialogContent></ng-template>
    </ng-container>
  </div>

  <ng-template #default>
    <ng-content></ng-content>
  </ng-template>

  <div class="right-sidebar__footer" *ngIf="showButtons">
    <cap-button styleClass="secondary"
                [label]="cancelButtonTranslationKey | translate"
                (click)="cancel()">
    </cap-button>

    <cap-button [styleClass]="submitButtonStyleClass"
                [label]="submitButtonTranslationKey | translate"
                [icon]="submitButtonIcon"
                (click)="submit()"
                [disabled]="submitButtonDisabled">
    </cap-button>
  </div>

  <div class="right-sidebar__footer send-mbo-button" *ngIf="showSendButton">
    <cap-button [styleClass]="sendButtonStyleClass"
                [label]="'mbo-check.send-message' | translate"
                [icon]="'fab fa-telegram-plane'"
                (click)="submit()"
                [disabled]="submitButtonDisabled">
    </cap-button>
  </div>
</p-sidebar>
