import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TwoFactorAuthService } from '@features/auth/services/two-factor-auth.service';

@Component({
  selector: 'app-auth-sms',
  templateUrl: './auth-sms.component.html',
  styleUrls: ['./auth-sms.component.scss'],
})
export class AuthSmsComponent implements OnInit {
  @Input()
  public smsCodeShown: boolean;

  @Output()
  public smsCodeEvent: EventEmitter<boolean> = new EventEmitter();

  public smsForm: FormGroup;
  public smsSent: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: TwoFactorAuthService,
  ) {
  }

  public ngOnInit(): void {
    this.smsForm = this.formBuilder.group({
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });

    this.authService.setupSms().subscribe((response) => {
      if (response?.identifier) {
        this.smsForm.patchValue({ phoneNumber: response.identifier });
      }
    });
  }

  public submit(): void {
    if (this.smsForm.valid) {
      this.authService.register('sms', this.smsForm.value.phoneNumber).subscribe(() => {
        this.smsCodeEvent.emit(true);
        this.smsSent = true;
      });
    }
  }
}
