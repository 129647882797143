import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  template: `
    <cap-editor *ngIf="!formControl.disabled" [formControl]="formControl"
                [formlyAttributes]="field"></cap-editor>

    <div class="readonly-editor" *ngIf="formControl.disabled" [innerHtml]="formControl.value">
    </div>
  `,
  styles: [
    `
      .readonly-editor {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 15px;
        color: #4f5152;
        line-height: 19px;
      }`
  ]
})
export class EditorFormlyTypeComponent extends FieldType {
}
