export enum Entities {
  users = 'user',
  students = 'student',
  roles = 'role',
  tenants = 'tenant',
  'base-data' = 'base-data',
  settings = 'setting',
  translations = 'translation',
  municipalities = 'municipality',
  instances = 'instance',
  schools = 'school',
  regions = 'region',
  phases = 'signup-phase',
  communications = 'communication',
  clusters = 'cluster',
  monitoring = 'monitoring',
  otherManagement = 'other-management',
  mboInstitutions = 'mbo-institution',
  mboCheckService = 'mbo-check-service',
  voInstitution = 'vo-institution',
  mboSchool = 'mbo-school',
  voSchool = 'vo-school',
  voGroups = 'vo-school-group',
  recipientGroup = 'recipient-group',
  template = 'template',
  module = 'module',
  signupStatus = 'signup-status',
  todoStudents = 'todo-student',
  mboCheck = 'mbo-check',
  mentors = 'mentor',
  statusAlias = 'signup-status-alias',
  mboWebservices = 'mbo-webservices',
  diplomaStudent = 'diploma-student',
  standardTemplate = 'standard-template',
  exports = 'exports',
  progress = 'vo-school-progress',

  // Legacy
  ddd = 'ddd',
  dossiers = 'dossiers',
  mboDossiers = 'mbo-dossiers',
  voDossiers = 'vo-dossiers',
  dossierTodo = 'dossier/todo',
  specialTransferManage = 'specialtransfer/manage',
  specialTransferTodo = 'specialtransfer/todo',
  specialTransfer = 'specialtransfer',
  specialTransfers = 'specialtransfers',
  training = 'training',
  setting = 'settings',
  pipeline = 'pipeline',
  choiceStudent = 'choice-student',
  reports = 'reports',
  report = 'report',
  choiceReport = 'choice_report',
  dddReport = 'ddd_vombo_report',
  transferReport = 'transfer_report',
  choice = 'choice',
  user = 'user',
  setup = 'setup',
  voAllDossiers = 'dossier#/tab/alle-dossiers',
  voArchivedDossiers = 'dossier#/tab/gearchiveerde-dossiers',
  voCompletedFiles = 'dossier#/tab/afgeronde-dossiers',
  voApprove = 'dossier#/tab/dossiers-in-akkoordverklaring',
  voPartB = 'dossier#/tab/dossiers-in-deel-b',
  voPartA = 'dossier#/tab/dossiers-in-deel-a',
  opps = 'opps',
  opp = 'opp',
  oppTodo = 'opp/todo',
  myStudents = 'dossier/student/grade/4',
  voReports = 'vo-reports',
  voTransferReport = 'transfer_report/vo/#',
  voDddReport = 'ddd_vombo_report/vo/#',
  voReport = 'report/vo/#',
  institutions = 'mbo-institutions',
  participatingChoices = 'participatingChoices',
  crebolist = 'crebolist',
  formSettings = 'form-settings',
  forms = 'forms#',
  formType = 'form_type',
  part = 'part',
  step = 'step',
  block = 'blok',
  question = 'question',
  reply = 'reply',
  answerTypes = 'answer_type',
  subMenuSettings = 'setting',
  general = 'general',
  notificationCenter = 'notification-center#/1/deel-a-opgestuurd/sentauser',
  emailDomain = 'email_domain',
  vo = 'vo',
  settingMboSchool = 'mbo_school#',
  settingRegion = 'region',
  course = 'course',
  transfers = 'transfers',
  transferSpecial = 'transferspecialtransfer',
  transferCare = 'transfercarepart',
  transferOpp = 'transferopp',
  connections = 'connections',
  connectionOverview = 'link',
  connectionType = 'linkblueprints',
  wsdlSetup = 'wsdl',
  eld2 = 'eld2',
  connectionSettings = 'linksettingblueprints',
  fields = 'linkfield',
  extraData = 'extradata',
  choiceLink = 'eld2choicelink',
  connectionMboCheck = 'link/mbocheck',
  tools = 'tools',
  cronOverview = 'cron/overview',
  cronLog = 'tools/cron/log',
  toolsCache = 'tools/cache',
  toolsStudentSet = 'tools/studentset',
  laravelLog = 'tools/logs/laravellog',
  errorLog = 'tools/logs/errorlog',

  lobDossier = 'lobdossier',
  management = 'management',
  format = 'format',
  competences = 'competences',
  managementRegion = 'instance/region',
  managementMbo = 'instance/choice',
  managementVo = 'instance/vo',
  assignment = 'assignment',
  assignmentRegionAll = 'assignment/region/all',
  assignmentGeneralArchived = 'assignment/general/archived',
  lobAssignment = 'lob-assignment',
  assignmentAll = 'assignment/all',
  assignmentReceived = 'assignment/received',
  assignmentShared = 'assignment/shared',
  assignmentArchived = 'assignment/archived',
  assignmentOverview = 'assignment/general/all',
  assignmentOverview2 = 'assignment/overview/format/2',
  assignmentOverview3 = 'assignment/overview/format/3',
  lobdossierMyStudents = 'student/all',
  lobdossierOverviewStudents = 'activity/index',
  carrierAssignment = 'carrier-assignment',
  carrierAssignmentAll = 'careerinterview/all',
  carrierAssignmentPreparation = 'assignment/overview/format/4',
  carrierAssignmentEvaluation = 'assignment/overview/format/5',
  handedInAssignments = 'handed-in-assignments',
  handedPerStudent = 'assigned/handed-in',
  handedPerAssignment = 'assignment/period/activated',
  activeAssignment = 'assigned/lobactivity/all',
  notAssigned = 'not_assigned',
  lobdossierReports = 'reports',
  reportActiveAssignments = 'report/active/assignments',
  lobVoorlichtingenManagement = 'info-management',
  lobVoorlichtingenSettings = 'settings',
  lobVoorlichtingenHbos = 'choicehbo',
  lobVoorlichtingenActivityType = 'activity_type',
  lobVoorlichtingenActivities = 'education/list/1',
  lobVoorlichtingenEducationDay = 'education_day',
  lobVoorlichtingenPhase = 'phase/index',
  lobVoorlichtingenOffer = 'offer',
  lobVoorlichtingenPhases = 'phase/settings/readonly',
  lobVoorlichtingenMyStudents = 'student/education_day?id=',
  lobVoorlichtingenBlock = 'block',
  lobVoorlichtingenPhasesSettings = 'phase/settings',
  lobVoorlichtingenClassroom = 'classroom',
  lobVoorlichtingenDigitalroom = 'digitalroom',
  lobVoorlichtingenSearch = 'student/education_day?id=42',
  lobVoorlichtingenRegistrations = 'education_report',
  lobVoorlichtingenSort = 'sort',

  monitorus = 'monitorus',
  monitorusReportsSubmenu = 'reports',
  monitorusList = '_monitorus/lists',
  monitorusImport = 'import',
  monitorusImportList = '_monitorus/index',
  monitorusTrainingTabel = '_monitorus/training',
  monitorusInfo = '_monitorus/info',
  monitorusStudentDetails = '_monitorus/students',
  monitorusData = '_monitorus/index',
  monitorusSettings = '_monitorus/settings',
  monitorusExplanation = '_monitorus/info',
  monitorusExports = '_monitorus/exports',
  monitorusCertificates = '_monitorus/cert',
  monitorusBenchmarking = '_monitorus/index',
  monitorusImportFiles = '_monitorus/uploadfile',
  monitorusReports = '_monitorus/benchmarking',

  lobActivities = 'lobactivities',
  offerReact = 'offer/react',
  activityOverview = 'activityoverview',
  activityOverviewStatus = 'activityoverview/status',
  activityOverviewStatusConcept = 'concept',
  activityOverviewStatusSubmitted = 'submitted',
  activityOverviewStatusDisapproved = 'disapproved',
  activityOverviewStatusApproved = 'approved',
  myStudentTab = 'student/tab',
  myStudentLeerlingenZonderInschrijving = 'leerlingen-zonder-inschrijving',
  myStudentLeerlingenMetInschrijving = 'leerlingen-met-inschrijving',
  myStudentLeerlingenMetOverinschrijving = 'leerlingen-met-overinschrijving',
  myStudentAlleLeerlingen = 'alle-leerlingen',
  registrations = 'registrations',
  registrationApplications = 'applications',
  registrationSignOuts = 'allsignouts',
  registrationOverApplications = 'overapplications',
  settingsDaysWindow = 'dayswindow',
  settingsPhases = 'phase',
  settingsRegion = 'region/setting',
  settingsMBO = 'choice/settings',
  settingsActivityType = 'activitytype',
  settingsOpenForRegions = 'region/choice/settings/openForRegions',
  settingsDateHandler = 'datehandler',
  settingsSurvey = 'google/survey',
  import = 'import',
  importActivity = 'activity/import',
  importActivityDate = 'activity/date/import',
}
