import { Component } from '@angular/core';
import { ToastPosition } from '@capturum/ui/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public position: ToastPosition = ToastPosition.TOP_RIGHT;
}
