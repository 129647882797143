import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { MenuModule } from 'primeng/menu';
import { CompleteModule } from '@capturum/complete';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from '@core/components/sidebar/sidebar.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { ImpersonateIndicatorComponent } from '@core/components/impersonate-indicator/impersonate-indicator.component';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    RoleSelectorComponent,
    ImpersonateIndicatorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenuModule,
    CompleteModule,
    TranslateModule,
    SharedModule,
    BrowserAnimationsModule,
    HorizontalNavbarModule,
    CapturumInputModule,
    CapturumDropdownModule,
    OverlayPanelModule,
    CapturumButtonModule,
    ConfirmDialogModule,
    TooltipModule,
  ]
})
export class CoreModule {
}
