import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Intergrip 2.0',
  production: false,
  hmr: false,
  name: 'Sync Test',
  baseUrl: 'https://misty-beirut-ryz6j4qt64tt.vapor-farm-b1.com/api',
  sentryUrl: null,
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/'
};
