import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as CapturumAuthService } from '@capturum/auth';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PageRoute } from '@core/enums/general/routes.enum';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { ResetUserState, SetUser } from '@core/state/user/user.actions';
import { tap } from 'rxjs/operators';
import { AuthProvider } from '@capturum/auth/lib/auth.provider';
import { UserModel } from '../../user/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CapturumAuthService implements AuthProvider {
  constructor(
    config: AuthConfig,
    private httpClient: HttpClient,
    private router: Router,
    private store: Store,
  ) {
    super(config, httpClient);
  }

  public handleSuccessfulLogin(response: any): void {
    if (response.method) {
      this.router.navigate([PageRoute.twoFactorLogin], {
        queryParams: {
          method: response.method,
          userId: response.user_id,
        },
      });
    } else {
      this.router.navigate(['/']);
      this.store.dispatch(new SetUser(this.getUser()));
    }
  }

  public activate(token: string, userId: string, postData: { password: string, password_confirmation: string }): Observable<void> {
    return this.httpClient.post<void>(`${environment.baseUrl}/auth/user/${userId}/activate`, postData, { params: { token } });
  }

  public logout(): Observable<boolean> {
    return super.logout().pipe(tap(() => {
      this.store.dispatch(new ResetUserState());
    }));
  }
}
