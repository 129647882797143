import { ActivatedRoute, Router } from '@angular/router';
import { Entities } from '@core/components/entities.enum';
import { FilterMatchMode, TableAction } from '@capturum/ui/api';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { FilterConfig } from '@core/models/filter-config.model';
import { LayoutService } from '@shared/services/layout.service';
import { PageConfig, PageConfigAction } from '@core/models/page-config.model';
import { ConfirmationService, FilterMetadata } from 'primeng/api';
import { NgxPermissionsService } from 'ngx-permissions';
import { ModuleService } from '../../features/module/services/module.service';
import { first, map } from 'rxjs/operators';

@Component({ template: '' })
export class IntBaseListComponent<T> extends BaseListComponent<T> implements OnInit {
  @ViewChild(CapturumInfoTableComponent, { static: false })
  public infoTable: CapturumInfoTableComponent;

  public tableColumns: InfoTableColumn[];
  public filterConfig: FilterConfig[];
  public removeDataWrappers = false;

  protected entity: Entities;
  protected router: Router;
  protected route: ActivatedRoute;
  protected layoutService: LayoutService;
  protected confirmService: ConfirmationService;
  protected moduleService: ModuleService;
  protected ngxPermissionService: NgxPermissionsService;

  constructor(public injector: Injector) {
    super(injector, injector.get(TranslateService));

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.layoutService = injector.get(LayoutService);
    this.confirmService = injector.get(ConfirmationService);
    this.ngxPermissionService = injector.get(NgxPermissionsService);
    this.moduleService = injector.get(ModuleService);
  }

  public ngOnInit(): void {
    this.moduleService.currentModule$.pipe(
      first(),
    ).subscribe((module) => {
      this.layoutService.setPageConfig(this.getDefaultConfig());
    });
  }

  public add(): void {
    this.router.navigate([`../add`], { relativeTo: this.route });
  }

  public edit(id: string): void {
    this.router.navigate([`../${id}`], { relativeTo: this.route });
  }

  public filterGlobal(value: string): void {
    this.infoTable.primeNGTable.filterGlobal(value, 'contains');
  }

  public filter(event: { value: any, field: string, matchMode: string }): void {
    this.infoTable.filterTable(event.value, event.field, event.matchMode as FilterMatchMode);
  }

  public reset(): void {
    this.infoTable.resetFilters();
  }

  public getViewEntityTableRowAction(icon: string): (TableAction & { callback: (item: any) => void }) {
    return {
      label: this.translateService.instant('intergrip.entity.view', { entity: this.getEntityTranslation }),
      value: '',
      icon,
      callback: (item) => {
        this.edit(item.id);
      },
    };
  }

  public getShowHistoryTableRowAction(callback: (id: string) => void): TableAction {
    return {
      label: this.translateService.instant('intergrip.button.show-history'),
      value: '',
      icon: 'fas fa-history',
      callback: (item) => {
        callback(item.id);
      },
    };
  }

  public getDeleteEntityTableRowAction(): TableAction {
    return {
      label: this.translateService.instant('intergrip.entity.delete', {
        entity: this.getEntityTranslation,
      }),
      key: 'delete',
      hidden: this.moduleService.getCurrentModule().pipe(map((module) => {
        return !this.ngxPermissionService.getPermission(`${module}.${this.entity}.manage`);
      })),
      icon: 'fas fa-trash-alt',
      callback: (item) => {
        this.deleteItemWithConfirm(item.id);
      },
    };
  }

  public getEditEntityTableRowAction(): TableAction {
    return {
      label: this.translateService.instant(`intergrip.entity.edit`, {
        entity: this.getEntityTranslation,
      }),
      hidden: !this.ngxPermissionService.getPermission(`intergrip.${this.entity}.manage`),
      icon: 'fas fa-pencil',
      callback: (item) => {
        this.edit(item.id);
      },
    };
  }

  public get getEntityTranslation(): string {
    return this.translateService.instant(`${this.moduleService.currentModule}.${this.entity}.entity_name`);
  }

  public getDefaultConfig(module: string = this.moduleService.currentModule): PageConfig {
    return {
      title: this.translateService.instant(`${module}.${this.entity}.overview.title`),
      buttons: [
        this.defaultAddButton,
      ],
    };
  }

  public get defaultAddButton(): PageConfigAction {
    return {
      label: 'intergrip.entity.add',
      icon: 'fas fa-plus',
      styleClass: 'primary',
      translateParams: {
        entity: this.entity,
      },
      permissions: [`${this.moduleService.currentModule}.${this.entity}.manage`],
      callback: () => {
        this.router.navigate(['../add'], { relativeTo: this.route });
      },
    };
  }

  public deleteItemWithConfirm(id: number | string, item?: string, message?: string): void {
    this.confirmationService.confirm({
      message: this.translateService.instant('intergrip.confirmation.message'),
      acceptLabel: this.translateService.instant('intergrip.entity.delete-item'),
      rejectLabel: this.translateService.instant('intergrip.entity.cancel'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'border-0 bg-danger',
      accept: () => {
        this.deleteItem(id, item);
      },
      reject: () => {
        this.loading = false;
      },
    });
  }

  public getManageModulesTableActionRow(): TableAction {
    return {
      label: this.translateService.instant('intergrip.module.manage-modules'),
      permissions: ['intergrip.module.manage'],
      icon: 'fas fa-cogs',
      callback: (item) => {
        const tenantId = item.tenant_id ? item.tenant_id : item?.tenant?.id;

        if (tenantId) {
          this.router.navigate([`/manage/${Entities.module}/${this.entity}/${tenantId}/module`]);
        }
      },
    };
  }

  public get activeFilters(): { [key: string]: FilterMetadata | FilterMetadata[] } {
    if (this.infoTable && this.infoTable.activeFilters) {
      const activeFilters = this.infoTable.activeFilters;

      for (const key in activeFilters) {
        if (activeFilters.hasOwnProperty(key)) {
          const value = (activeFilters[key] as FilterMetadata).value;

          if (Date.parse(value) && !(value instanceof Date)) {
            activeFilters[key] = { ...activeFilters[key], value: new Date(value) };
          }
        }
      }

      return activeFilters;
    }

    return {};
  }
}
