<div class="panel">
  <div class="row head">
    <div class="col-12 dates">
      <ng-container *ngFor="let item of schoolYears">
        <span class="date" [class.active]="item.year === activeYear?.year" (click)="setActiveYear(item)">
          {{ item.year }}
        </span>
      </ng-container>
    </div>
  </div>

  <ng-container *ngFor="let item of activeYear?.roles">
    <div class="row item" (click)="setRole(item)">
      <div class="col-10">
        <span class="label" [class.active]="item.active">{{ item.role }}</span>
        <span class="description">{{ item.role_type }}</span>
      </div>

      <div class="col-2 text-right">
          <span class="favorite-icon icon" [class.active]="item.favorite" (click)="updateFavoriteRole($event, item.id)">
            <i [class]="item.favorite ? 'fas fa-star' : 'far fa-star'"></i>
          </span>
      </div>
    </div>
  </ng-container>
</div>
