import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { FormUtils } from '@core/utils/form.utils';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormCardComponent implements OnDestroy {
  get submitted(): boolean {
    return this._submitted;
  }

  @Input()
  set submitted(value: boolean) {
    this._submitted = value;

    if (value) {
      FormUtils.markAsTouched(this.form);
      this.cdr.detectChanges();
    }
  }

  get form(): FormGroup {
    return this._form;
  }

  @Input()
  set form(value: FormGroup) {
    this._form = value;
    this.cdr.detectChanges();

    if (value) {
      this.subscription = this.form.statusChanges.subscribe(() => {
        this.cdr.detectChanges();
      });
    }
  }

  @Input() public icon: string;
  @Input() public title: string;
  @Input() public model: any;
  @Input() public fields: FormlyFieldConfig[];
  @Input() public options: FormlyFormOptions;
  private _submitted: boolean;

  private _form: FormGroup;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
