import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PageConfigAction } from '@core/models/page-config.model';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
  @Input()
  public menuItems: PageConfigAction[];
}
