import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceStudent, TodoStudent } from '../../features/todo-student/models/todo-student.model';

@Pipe({
  name: 'choiceStatus'
})
export class ChoiceStatusPipe implements PipeTransform {
  public transform(student: TodoStudent): string {
    const bestChoiceStudent: ChoiceStudent[] = student.choiceStudents.filter(choiceStudent => choiceStudent.bestChoice === true);

    return bestChoiceStudent.length > 0 ? bestChoiceStudent[0].latestChoiceUpdate.signupStatus.name : '-';
  }
}
