import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
  /**
   * Capitalize the first character
   *
   * @param value: string
   * @return string
   */
  public transform(value: string): string {
    return value
      ? `${value.charAt(0).toUpperCase()}${value.slice(1, value.length)}`
      : value;
  }
}
