import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import { CompleteResolver, IndexedDbGuard } from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { TwoFactorLoginComponent } from './features/auth/two-factor-login/two-factor-login.component';
import { BasicLayoutComponent } from '@core/layout/basic-layout/basic-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Entities } from '@core/components/entities.enum';
import { TwoFactorResetMailResponseComponent } from './features/auth/pages/two-factor-reset-mail-response/two-factor-reset-mail-response.component';
import { ResolvedPermissionsGuard } from '@shared/guards/resolved-permissions.guard';
import { UriUtils } from '@shared/utils/uri.utils';
import { PageRoute } from '@core/enums/general/routes.enum';
import { ActivateUserComponent } from './features/auth/pages/activate-user/activate-user.component';
import { CustomPublicTranslationResolver } from '@shared/resolvers/custom-public-translation.resolver';
import { Modules } from '@core/enums/modules.enum';
import { LegacyComponent } from './features/legacy/pages/legacy/legacy.component';

const routes: Routes = [
  {
    path: 'mbocheck',
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: CustomPublicTranslationResolver,
    },
    loadChildren: () => import('./features/guest/guest.module').then((m) => m.GuestModule),
  },
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: CustomPublicTranslationResolver,
    },
    children: [
      ...loginRoutes[0].children,
      {
        path: 'two-factor',
        component: TwoFactorLoginComponent,
      },
      {
        path: 'disable/:token',
        component: TwoFactorResetMailResponseComponent,
      },
      {
        path: 'user/:id/activate',
        component: ActivateUserComponent,
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard, IndexedDbGuard],
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: '',
        canActivate: [ResolvedPermissionsGuard],
        component: BasicLayoutComponent,
        children: [
          {
            path: 'manage',
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: PageRoute.profile,
              },
              {
                path: Entities.tenants,
                loadChildren: () => import('./features/tenant/tenant.module').then((m) => m.TenantModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: 'tenant.manage',
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.roles,
                loadChildren: () => import('./features/role/role.module').then((m) => m.RoleModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: 'role.manage.tenant',
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.translations,
                loadChildren: () => import('./features/translation/translation.module').then((m) => m.TranslationModule),
              },
              {
                path: Entities['base-data'],
                loadChildren: () => import('./features/base-data/base-data.module').then((m) => m.BaseDataModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: 'base-data-value.manage.tenant',
                    redirectTo: '/',
                  },
                },
              },
              {
                path: Entities.settings,
                loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule),
              },
              {
                path: Entities.students,
                loadChildren: () => import('./features/student/student.module').then((m) => m.StudentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['intergrip.student.show', 'intergrip.student.manage'],
                    redirectTo: UriUtils.submenu(Entities.users),
                  },
                },
              },
              {
                path: Entities.users,
                loadChildren: () => import('./features/user/user.module').then((m) => m.UserModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['intergrip.user.show', 'user.show'],
                    redirectTo: UriUtils.submenu(Entities.instances),
                  },
                },
              },
              {
                path: Entities.instances,
                loadChildren: () => import('./features/instances/instances.module').then((m) => m.InstancesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      'intergrip.municipality.show',
                      'intergrip.school.show',
                      'intergrip.region.show',
                      'intergrip.mbo-institution.show',
                      'intergrip.vo-institution.show',
                      'intergrip.vo-school-group.show',
                    ],
                    redirectTo: UriUtils.submenu(Entities.communications),
                  },
                },
              },
              {
                path: Entities.communications,
                loadChildren: () => import('./features/communication/communication.module').then((m) => m.CommunicationModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      'intergrip.recipient-group.show',
                      'intergrip.template.show',
                      'intergrip.communication.show',
                    ],
                    redirectTo: UriUtils.submenu(Entities.otherManagement),
                  },
                },
              },
              {
                path: 'other-settings',
                loadChildren: () => import('./features/other-settings/other-settings.module').then((m) => m.OtherSettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: 'intergrip.shared-file.show',
                    redirectTo: PageRoute.profile,
                  },
                },
              },
              {
                path: 'config',
                loadChildren: () => import('./features/config/config.module').then((m) => m.ConfigModule),
              },
              {
                path: Entities.module,
                loadChildren: () => import('./features/module/module.module').then((m) => m.ModuleModule),
              },
              {
                path: Entities.mboCheck,
                loadChildren: () => import('./features/mbo-check/mbo-check.module').then((m) => m.MboCheckModule),
              },
              {
                path: Entities.statusAlias,
                loadChildren: () => import('./features/status-alias/status-alias.module').then((m) => m.StatusAliasModule),
              },
              {
                path: Entities.mboWebservices,
                loadChildren: () => import('./features/mbo-webservices/mbo-webservices.module').then((m) => m.MboWebservicesModule),
              },
              {
                path: 'profile',
                loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
              },
              {
                path: Entities.mboCheckService,
                loadChildren: () => import('./features/mbo-check-service/mbo-check-service.module').then((m) => m.MboCheckServiceModule),
              },
            ],
          },
          {
            path: 'transfer-mbo',
            children: [
              {
                path: Entities.todoStudents,
                loadChildren: () => import('./features/mbo-todo-student/mbo-todo-student.module').then((m) => m.MboTodoStudentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [
                      'transfer-mbo.mbo.todo-student.show',
                      'transfer-mbo.mbo.student.show',
                    ],
                    redirectTo: UriUtils.submenu(Entities.todoStudents, Modules.transferMbo),
                  },
                },
              },
              {
                path: Entities.reports,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/mbo-reports/mbo-reports.module').then((m) => m.MboReportsModule),
                data: {
                  permissions: {
                    only: [
                      'transfer-mbo.mbo-mbo.choice-student.export',
                      'transfer-mbo.mbo-mbo.best-choice-student.export'
                    ],
                    redirectTo: UriUtils.submenu(Entities.todoStudents, Modules.transferMbo),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.todoStudents, Modules.transferMbo),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'transfer-vo',
            children: [
              {
                path: Entities.phases,
                loadChildren: () => import('./features/phases/phases.module').then((m) => m.PhasesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['transfer-vo.signup-phase.show', 'transfer-vo.signup-phase.manage'],
                    redirectTo: UriUtils.list(Entities.signupStatus, Modules.transferVo),
                  },
                },
              },
              {
                path: Entities.signupStatus,
                loadChildren: () => import('./features/sign-status/signup-status.module').then((m) => m.SignupStatusModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: 'transfer-vo.signup-status.show',
                    redirectTo: UriUtils.submenu(Entities.todoStudents, Modules.transferVo),
                  },
                },
              },
              {
                path: Entities.todoStudents,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/todo-student/todo-student.module').then((m) => m.TodoStudentModule),
                data: {
                  permissions: {
                    only: [
                      'transfer-vo.todo-student.show',
                      'transfer-mbo.todo-student.show',
                      'transfer-vo.student.show',
                      'transfer-mbo.student.show',
                    ],
                    redirectTo: UriUtils.list(Entities.students),
                  },
                },
              },
              {
                path: Entities.reports,
                canActivate: [NgxPermissionsGuard],
                loadChildren: () => import('./features/transfer-reports/transfer-reports.module').then((m) => m.TransferReportsModule),
                data: {
                  permissions: {
                    only: [
                      'transfer-vo.choice-student.export',
                      'transfer-vo.choice-student.best-choice-export',
                      'transfer-vo.mbo-choice-student.export',
                    ],
                    redirectTo: UriUtils.list(Entities.todoStudents, Modules.transferVo),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.list(Entities.phases, Modules.transferVo),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'zoekjouwstudie',
            children: [
              {
                path: Entities.lobVoorlichtingenSettings,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.settings.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenManagement,
                loadChildren: () => import('./features/info-management/info-management.module').then((m) => m.InfoManagementModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.management.show', 'zoekjouwstudie.management-admin.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenOffer,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.offer.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenPhases,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.phases.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenPhases, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenMyStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.my-students.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenActivities,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.activities.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenActivities, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenSearch,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.search.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenSearch, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenRegistrations,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.registrations.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenRegistrations, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: Entities.lobVoorlichtingenSort,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['zoekjouwstudie.sort.show'],
                    redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenSort, Modules.lobVoorlichtingen),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.lobVoorlichtingen),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'ddd',
            children: [
              {
                path: Entities.mboDossiers,
                loadChildren: () => import('./features/mbo-dossier/mbo-dossier.module').then((m) => m.MboDossierModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.mbo-dossier.show'],
                    redirectTo: UriUtils.submenu(Entities.reports, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.voDossiers,
                loadChildren: () => import('./features/vo-dossier/vo-dossier.module').then((m) => m.VoDossierModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.vo-dossier.show'],
                    redirectTo: UriUtils.submenu(Entities.mboDossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.specialTransfers,
                loadChildren: () => import('./features/special-transfers/special-transfers.module').then((m) => m.SpecialTransfersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.mbo-special-transfer.show', 'ddd.vo-special-transfer.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.training,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.training,
                  permissions: {
                    only: ['ddd.training.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.setting,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.setting,
                  permissions: {
                    only: ['ddd.mbo-settings.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.subMenuSettings,
                loadChildren: () => import('./features/intergrip1-setting/intergrip1-setting.module').then((m) => m.Intergrip1SettingModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.setting,
                  permissions: {
                    only: ['ddd.settings.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.pipeline,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  url: Entities.pipeline,
                  permissions: {
                    only: ['ddd.pipeline.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.reports,
                loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.report.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.voReports,
                loadChildren: () => import('./features/vo-report/vo-report.module').then((m) => m.VoReportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.vo-report.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.setup,
                loadChildren: () => import('./features/setup/setup.module').then((m) => m.SetupModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.setup.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.opps,
                loadChildren: () => import('./features/vo-opp/vo-opp.module').then((m) => m.VoOppModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.vo-opp.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.myStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.student.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.institutions,
                loadChildren: () => import('./features/institutions/institutions.module').then((m) => m.InstitutionsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.mbo-institution.show'],
                    redirectTo: UriUtils.submenu(Entities.dossierTodo, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.dossiers,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.dossier.show'],
                    redirectTo: UriUtils.submenu(Entities.voDossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.opp,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.opp.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.specialTransferManage,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.special-transfer.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.formSettings,
                loadChildren: () => import('./features/form-setting/form-setting.module').then((m) => m.FormSettingModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.form-setting.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.transfers,
                loadChildren: () => import('./features/transfers/transfers.module').then((m) => m.TransfersModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.transfer.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.connections,
                loadChildren: () => import('./features/connection/connection.module').then((m) => m.ConnectionModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.connection.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: Entities.tools,
                loadChildren: () => import('./features/tools/tools.module').then((m) => m.ToolsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['ddd.tool.show'],
                    redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.dossiers, Modules.ddd),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.monitorus,
            children: [
              {
                path: Entities.monitorusList,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.list.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusList, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusImport,
                loadChildren: () => import('./features/import/import.module').then((m) => m.ImportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.import.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusReportsSubmenu, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusInfo,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.info.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusInfo, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusStudentDetails,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.student-details.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusStudentDetails, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusData,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.data.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusData, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusSettings,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.settings.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusSettings, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusReportsSubmenu,
                loadChildren: () => import('./features/monitorus-reports/monitorus-reports.module').then((m) => m.MonitorusReportsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.reports.show', 'monitorus.mbo-reports.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusData, Modules.monitorus),
                  },
                },
              },
              {
                path: Entities.monitorusImportFiles,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['monitorus.import-files.show'],
                    redirectTo: UriUtils.submenu(Entities.monitorusImportFiles, Modules.monitorus),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.monitorusImport, Modules.monitorus),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.lobDossier,
            children: [
              {
                path: Entities.management,
                loadChildren: () => import('./features/management/management.module').then((m) => m.ManagementModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.management.show'],
                    redirectTo: UriUtils.submenu(Entities.lobAssignment, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.assignment,
                loadChildren: () => import('./features/assignment/assignment.module').then((m) => m.AssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.assignment.show'],
                    redirectTo: UriUtils.submenu(Entities.lobAssignment, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.lobAssignment,
                loadChildren: () => import('./features/lob-assignment/lob-assignment.module').then((m) => m.LobAssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.lob-assignment.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.lobdossierMyStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.lob-assignment.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.lobdossierOverviewStudents,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.overview-students.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.carrierAssignment,
                loadChildren: () => import('./features/carrier-assignment/carrier-assignment.module').then((m) => m.CarrierAssignmentModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.carrier-assignment.show', 'lobdossier.mbo-carrier-assignment.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.handedInAssignments,
                loadChildren: () => import('./features/handed-in-assignments/handed-in-assignments.module').then((m) => m.HandedInAssignmentsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.vo-handed-in-assignments.show',  'lobdossier.mbo-handed-in-assignments.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.notAssigned,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.handed-in-assignments.not-assigned.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: Entities.lobdossierReports,
                loadChildren: () => import('./features/lobdossier-report/lobdossier-report.module').then((m) => m.LobdossierReportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobdossier.report.show'],
                    redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                  },
                },
              },
              {
                path: '',
                redirectTo: UriUtils.submenu(Entities.management, Modules.lobdossier),
                pathMatch: 'full',
              },
            ],
          },
          {
            path: Entities.lobActivities,
            children: [
              {
                path: Entities.offerReact,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.offer.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.activityOverview,
                loadChildren: () => import('./features/lob-activity-activities/lob-activity-activities.module').then((m) => m.LobActivityActivitiesModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.activities.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.myStudentTab,
                loadChildren: () => import('./features/lob-activity-my-students/lob-activity-my-students.module').then((m) => m.LobActivityMyStudentsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.admin-my-students.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.registrations,
                loadChildren: () => import('./features/lob-activity-registrations/lob-activity-registrations.module').then((m) => m.LobActivityRegistrationsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.registrations.show', 'lobactivities.mbo-registrations.show', 'lobactivities.vo-registrations.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.settings,
                loadChildren: () => import('./features/lob-activity-settings/lob-activity-settings.module').then((m) => m.LobActivitySettingsModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.settings.show', 'lobactivities.admin-settings.show', 'lobactivities.vo-settings.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.import,
                loadChildren: () => import('./features/lob-activity-import/lob-activity-import.module').then((m) => m.LobActivityImportModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.import.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
              {
                path: Entities.students,
                component: LegacyComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: ['lobactivities.students.show'],
                    redirectTo: UriUtils.submenu(Entities.lobActivities, Modules.lobActivities),
                  },
                },
              },
            ],
          },
          {
            path: '**',
            redirectTo: UriUtils.submenu(Entities.students),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: UriUtils.submenu(Entities.students),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
