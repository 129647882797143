import { UserModel } from '../../../features/user/models/user.model';

const currentStateEntity = 'User';

export class SetUser {
  public static readonly type = `[${currentStateEntity}] Set User`;
  constructor(public user: UserModel) {
  }
}

export class ImpersonateUser {
  public static readonly type = `[${currentStateEntity}] Impersonate User`;
  constructor(public userToImpersonate: UserModel) {
  }
}

export class StopImpersonateUser {
  public static readonly type = `[${currentStateEntity}] Stop Impersonate User`;
}

export class ResetUserState {
  public static readonly type = `[${currentStateEntity}] Reset state`;
}
