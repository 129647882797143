<div class="account-detail-item">
  <div class="row-item-label mb-3">
    <ng-container *ngTemplateOutlet="labelTemplate || defaultLabel">
    </ng-container>

    <ng-template #defaultLabel>
      <span class="account-detail-item__label">{{ label }}</span>
    </ng-template>
  </div>

  <div class="row-item-value">
    <a (click)="clickItem()">
      {{ link }}
    </a> <i class="fas fa-chevron-right primary-text"></i>
  </div>
</div>
