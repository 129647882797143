import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PageRoute } from '@core/enums/general/routes.enum';
import { MenuItemService } from '@shared/services/menu-item.service';
import { FilterMatchMode, ToastService } from '@capturum/ui/api';
import { UserService } from '../../../features/user/services/user.service';
import { RoleService } from '../../../features/role/services/role.service';
import { UserModel } from '../../../features/user/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { SchoolYear } from '@core/models/school-year.model';
import { RoleItem } from '@core/components/header/role-item.model';
import { ModuleService } from '../../../features/module/services/module.service';
import { UriUtils } from '@shared/utils/uri.utils';
import { Entities } from '@core/components/entities.enum';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { ModuleIconMap, Modules } from '@core/enums/modules.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { SubSink } from 'subsink';
import { Role } from '../../../features/role/models/role.model';
import { Select } from '@ngxs/store';
import { UserState } from '@core/state/user/user.state';
import { LocaleService } from '@core/services/locale.service';
import Locale from '@capturum/auth/lib/locale.interface';
import User from '@capturum/auth/lib/user.interface';
import { ListOptions } from '@capturum/api';

export interface ProfileMenuItem extends MenuItem {
  label$: Observable<string>;
}

enum ArrowSide {
  Left = 'left',
  Right = 'right'
}

const arrowWidth = 33;

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(UserState.user)
  public user$: Observable<UserModel>;

  public profileMenuItems$: Observable<ProfileMenuItem[]>;
  public selectedLanguage: string;
  public currentUser: UserModel;
  public settingPermissions: string[] = [];
  public moduleMenuItems$: Observable<NavMenuItem[]>;
  public schoolYears: SchoolYear[] = [];
  public currentRole: string;
  public arrowSide = ArrowSide;
  public currentRole$: Observable<Role>;
  public localeList$: Observable<Locale[]>;
  @ViewChild('modulesMenu', { read: ElementRef })
  public modulesMenu: ElementRef;
  public showSliderArrows = false;
  private slidedToRight = 0;
  private subs = new SubSink();
  private availableLocales: string[] = ['nl', 'en'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private authService: AuthService,
    private menuItemService: MenuItemService,
    private moduleService: ModuleService,
    private localeService: LocaleService,
  ) {
    this.settingPermissions = [
      'user.manage.tenant',
      'tenant.manage',
      'module.manage.tenant',
      'translation.manage.tenant',
      'role.manage.tenant',
    ];
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    let modulesWidth = 0;
    this.modulesMenu.nativeElement.querySelectorAll('cap-nav-item').forEach((node) => {
      modulesWidth += node.offsetWidth;
    });
    this.modulesMenu.nativeElement.querySelector('.navbar-nav').style = '';
    this.slidedToRight = 0;
    if (window.innerWidth < modulesWidth) {
      this.showSliderArrows = true;
      return;
    }
    this.showSliderArrows = false;
  }

  public ngOnInit(): void {
    this.moduleMenuItems$ = this.roleService.roleUpdated$.pipe(
      switchMap(() => this.moduleService.getUserModules()),
      map((modules) => {
        return [
          ...modules.map((module) => {
            return {
              title: `${module.key}.module-name`,
              routerUrl: module.key,
              icon: ModuleIconMap[module.key],
              permissions: [],
              key: module.key,
              color: module.color,
            };
          }),
          {
            title: 'intergrip.menu.manage',
            activeSubRoute: `/${Modules.intergrip}`,
            routerUrl: UriUtils.submenu(Entities.students),
            exact: false,
            styleClass: 'primary-menu-item',
            key: 'intergrip',
            icon: 'fas fa-cog',
            permissions: [],
            modules: [],
          },
        ];
      }));

    this.setActiveMenuItem(this.router.url);

    this.router.events.pipe(filter((routerEvent) => {
      return routerEvent instanceof NavigationEnd;
    })).subscribe((event: NavigationEnd) => {
      this.setActiveMenuItem(event.urlAfterRedirects);
    });

    this.currentUser = this.authService.getUser();
    this.currentRole = this.currentUser.currentRole.name;

    this.selectedLanguage = this.currentUser?.locale?.code || 'en';
    this.translateService.use(this.selectedLanguage);

    const options: ListOptions = {
      filters: [
        {
          value: this.availableLocales,
          field: 'code',
          operator: FilterMatchMode.IN,
        },
      ],
    };

    this.profileMenuItems$ = this.localeService.getLocales(options).pipe(
      map((locales) => {
        return [
          {
            label$: this.translateService.stream('intergrip.profile.entity_name'),
            icon: 'fas fa-user-circle',
            command: () => {
              this.navigateToProfile();
            },
          },
          {
            label$: this.translateService.stream('intergrip.profile.sign-out.label'),
            icon: 'fas fa-sign-out-alt',
            command: () => {
              this.logout();
            },
          },
          {
            label$: this.translateService.stream('intergrip.locale.change-language.label'),
            disabled: true,
          },
          ...locales.map((locale) => {
            return {
              label$: of(locale.name),
              icon: 'fas fa-user-circle',
              title: locale.code,
              command: () => {
                this.translateService.setDefaultLang(locale.code);
                this.translateService.reloadLang(locale.code).pipe(
                  first(),
                  switchMap(() => this.translateService.use(locale.code)),
                  first(),
                ).subscribe();

                this.localeService.saveCurrentUserLocale(this.currentUser.id, locale.id).subscribe((user: User) => {
                  this.userService.updateUserLocalStorage(locale);
                });

                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['/'], { relativeTo: this.route });
                });
              },
            };
          }),
        ];
      }),
    );

    this.subs.add(
      combineLatest([this.userService.userChanged$, this.roleService.roleUpdated$]).pipe(switchMap(() => {
        return this.roleService.all();
      })).subscribe((response) => {
        if (response) {
          this.schoolYears = response;
        }
      }),
    );
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.onWindowResize(), 500);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Navigate to given url
   *
   * @param url
   */
  public navigate(url: string): void {
    this.router.navigate([url]);
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
    this.authService.logout().subscribe(result => {
      if (result) {
        this.navigate(PageRoute.login);
      }
    });
  }

  public navigateToProfile(): void {
    this.router.navigate([PageRoute.profile]);
  }

  public updateFavoriteRole(id: string): void {
    this.userService.setRolePermission(id).subscribe((response) => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('toast.success.update', { entity: this.translateService.instant('intergrip.favorite-role.entity_name') }),
      );
    });
  }

  public setActiveRole(role: RoleItem): void {
    this.schoolYears = this.schoolYears.map((year) => {
      return {
        ...year,
        roles: year.roles.map((yearRole) => {
          yearRole.active = yearRole.id === role.id;

          return yearRole;
        }),
      };
    });
    setTimeout(() => this.onWindowResize(), 500);
    this.currentRole = role.role;

    this.roleService.setRole(role.id).subscribe(() => {
      // Refresh the page to re-activate the route guards
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/'], { relativeTo: this.route });
      });
    });
  }

  public onClickLocale(item: MenuItem): void {
    if (this.availableLocales.some(code => item.title === code)) {
      this.selectedLanguage = item.title;
    }

    item.command();
  }

  public swipeModule(side: ArrowSide): void {
    const navBar = this.modulesMenu.nativeElement.querySelector('.navbar-nav');
    const wrapperDebounce = this.modulesMenu.nativeElement.getBoundingClientRect();
    for (const item of this.modulesMenu.nativeElement.querySelectorAll('cap-nav-item')) {
      const rect = item.getBoundingClientRect();
      if (side === ArrowSide.Left && (rect.left + rect.width + arrowWidth > 0)) {
        this.slidedToRight += rect.left - arrowWidth;
        if (this.slidedToRight - 1 <= arrowWidth) {
          this.slidedToRight = 0;
        }
        break;
      }
      if (side === ArrowSide.Right && (rect.right > wrapperDebounce.right + Math.abs(this.slidedToRight))) {
        this.slidedToRight += rect.right - wrapperDebounce.x - wrapperDebounce.width;
        break;
      }
    }
    navBar.style = `right: ${this.slidedToRight}px`;
  }

  private setActiveMenuItem(url: string): void {
    const activeItem = this.menuItemService.menuItems.find((menuItem) => {
      return url.includes(menuItem.activeSubRoute);
    });

    this.menuItemService.setActiveMenuitem(activeItem);
  }
}
