import { Component, OnInit } from '@angular/core';
import { TwoFactorAuthService } from '../../services/two-factor-auth.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PageRoute } from '@core/enums/general/routes.enum';

@Component({
  selector: 'app-two-factor-reset-mail-response',
  templateUrl: './two-factor-reset-mail-response.component.html',
  styleUrls: ['./two-factor-reset-mail-response.component.scss'],
})
export class TwoFactorResetMailResponseComponent implements OnInit {
  public message$: Observable<string | Observable<string>>;
  public isError: boolean;
  public routes = PageRoute;

  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit(): void {
    this.message$ = this.route.paramMap.pipe(
      map((params) => {
        return params.get('token');
      }),
      switchMap<string, any>((token): Observable<string> => {
        return this.twoFactorAuthService.disable2fa(token).pipe(
          map((response): string => {
            return 'auth.2fa.reset-mail.success';
          }),
        );
      }),
      catchError<any, Observable<string>>((error) => {
        this.isError = true;

        return of<string>('auth.2fa.link_no_longer_valid');
      }),
    );
  }

}
