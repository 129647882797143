<span class="mail-tag-list">
  <span class="fa-stack" (click)="overlayPanel.toggle($event)">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-tags fa-stack-1x fa-inverse"></i>
  </span>
</span>

<p-overlayPanel #overlayPanel appendTo="body" [style]="{'width': '350px'}">
  <ng-template pTemplate>
    <div class="tags-overlay">
      <h5 class="tags-overlay__title">
        {{ 'intergrip.template.tags.title' | translate }}
      </h5>
      <div class="tags-overlay__sub-title">
        {{ 'intergrip.template.tags.subtitle' | translate }}
      </div>

      <div class="tags-overlay__list">
        <div class="tags-overlay__list-item d-flex justify-content-between" *ngFor="let tag of tags">
          <div class="tags-overlay__list-item-label">
            [{{ tag.value }}]
          </div>

          <div class="tags-overlay__list-item-arrow">
            <i class="fas fa-arrow-right"></i>
          </div>

          <div class="tags-overlay__list-item-label">
            {{ tag.label }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
