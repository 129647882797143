import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { PermissionService } from '@capturum/complete';

@Injectable({ providedIn: 'root' })
export class ResolvedPermissionsGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private ngxPermissionsGuard: NgxPermissionsGuard,
    private permissionService: PermissionService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.permissionService.loadPermissions().toPromise();
  }

  public canLoad(route: Route): Promise<boolean> {
    return this.permissionService.loadPermissions().toPromise();
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.permissionService.loadPermissions().toPromise();
  }
}
